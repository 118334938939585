import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';

import { DebounceInput } from 'react-debounce-input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilter } from '@fortawesome/free-solid-svg-icons';

import { Messages, Panel, PanelBody, PanelFooter, PanelTitle } from 'apriori-react-core';

import {
  COST_STATUS,
  EXPORT_BILL_OF_MATERIALS,
  FILTER_PARTS,
  INCOMPLETE,
  INCOMPLETE_MSG,
  NO_LINE_ITEMS_FOUND,
  NO_PARTS_MATCHED,
  NO_PARTS_MATCHED_MSG,
  READY_FOR_EXPORT,
  READY_FOR_EXPORT_MSG

} from '../../constants/messages';

import Paginator from '../widgets/Paginator';

import LineItemCard from './LineItemCard';

class LineItemList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pageSize: 10,
      searchTerm: null,
      selectedCostStatusFilter: COST_STATUS,
      selectedCostStatusFilterValue: 'none'
    };
    
    props.resetFilter();

    this.paginator = React.createRef();
  }

  export = () => this.props.exportBillOfMaterials(this.props.billOfMaterials.identity);

  hasContent = () => {
    const { lineItems } = this.props;
    return !!lineItems && lineItems.length > 0;
  };

  pageChanged = (pageNumber, pageSize) => {
    this.setState({ pageSize });

    this.props.listLineItems(
      this.props.billOfMaterials.identity,
      pageNumber,
      pageSize,
      this.state.searchTerm,
      this.state.selectedCostStatusFilterValue);
  };

  searchLineItems = (event) => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm });

    this.props.listLineItems(
      this.props.billOfMaterials.identity,
      1,
      this.state.pageSize,
      searchTerm,
      this.state.selectedCostStatusFilterValue);

    this.paginator.current.setPageNumber(1);
  };

  selectCostStatusFilter = (event) => {
    const costStatus = (event.currentTarget.value === 'none') ? COST_STATUS : event.currentTarget.textContent;
    const costStatusValue = (event.currentTarget.value === 'none') ? 'none' : event.currentTarget.value;

    this.setState({ selectedCostStatusFilter: costStatus, selectedCostStatusFilterValue: costStatusValue });

    this.props.listLineItems(
      this.props.billOfMaterials.identity,
      1,
      this.state.pageSize,
      this.state.searchTerm,
      costStatusValue);

    this.paginator.current.setPageNumber(1);
  };

  renderExportButton = () => ([
    <button
      key='line-item-list-export-button'
      type='button'
      id='export-button'
      className='btn btn-outline-primary icon-button mr-1'
      onClick={this.export}
    >
      <FontAwesomeIcon icon={faDownload} />
    </button>,
    <UncontrolledTooltip
      key='line-item-list-export-tooltip'
      placement='top'
      target='export-button'
    >
      {EXPORT_BILL_OF_MATERIALS}
    </UncontrolledTooltip >
  ]);

  renderFilterButton = () => ([
    <button
      key='line-item-list-filter-button'
      type='button'
      id='filter-button'
      className='btn btn-outline-primary icon-button'
    >
      <FontAwesomeIcon icon={faFilter} />
    </button>,
    <UncontrolledTooltip
      key='line-item-list-filter-tooltip'
      placement='top'
      target='filter-button'
    >
      {FILTER_PARTS}
    </UncontrolledTooltip >
  ]);

  renderFilterPanel = () => (
    <UncontrolledCollapse className='filter-actions' toggler='#filter-button'>
      <DebounceInput
        type='text'
        className='search form-control'
        placeholder='Search...'
        debounceTimeout={250}
        maxLength={255}
        onChange={this.searchLineItems}
      />
      <UncontrolledDropdown className='cost-status dropdown-outline' size='sm'>
        <DropdownToggle caret>
          {this.state.selectedCostStatusFilter}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem value='none' onClick={this.selectCostStatusFilter}>{Messages.NONE}</DropdownItem>
          <DropdownItem divider />
          <DropdownItem value={NO_PARTS_MATCHED} onClick={this.selectCostStatusFilter}>{NO_PARTS_MATCHED_MSG}</DropdownItem>
          <DropdownItem value={INCOMPLETE} onClick={this.selectCostStatusFilter}>{INCOMPLETE_MSG}</DropdownItem>
          <DropdownItem value={READY_FOR_EXPORT} onClick={this.selectCostStatusFilter}>{READY_FOR_EXPORT_MSG}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </UncontrolledCollapse>
  );

  renderCards = () => {
    return this.props.lineItems.map((lineItem) => (
      <LineItemCard
        key={lineItem.identity}
        lineItem={lineItem}
        selectLineItem={this.props.selectLineItem}
        selectedLineItem={this.props.selectedLineItem}
      />
    ));
  };

  render() {
    const { billOfMaterials, totalNumberOfLineItems } = this.props;

    return (
      <Panel className='line-item-list'>
        <PanelTitle>
          <div className='title-left'>
            <div className={`bill-of-materials-type ${billOfMaterials.type.toLowerCase()} mr-3`}>
              {billOfMaterials.type}
            </div>
            <div className='info'>
              <div>{billOfMaterials.name}</div>
              <span className='subtitle'>{billOfMaterials.filename}</span>
            </div>
          </div>
          <div className='title-right'>
            {this.renderExportButton()}
            {this.renderFilterButton()}
          </div>
        </PanelTitle>
        <div className='panel-filter'>
          {this.renderFilterPanel()}
        </div>
        <PanelBody hasContent={this.hasContent()} noContentMessage={NO_LINE_ITEMS_FOUND}>
          {this.renderCards()}
        </PanelBody>
        <PanelFooter>
          <Paginator
            ref={this.paginator}
            pageChangedCallback={this.pageChanged}
            pageSizeOptions={[10, 15, 20]}
            totalItemCount={totalNumberOfLineItems}
          />
        </PanelFooter>
      </Panel>
    );
  }
}

LineItemList.defaultProps = {
  selectedLineItem: null
};

LineItemList.propTypes = {
  billOfMaterials: PropTypes.instanceOf(Object).isRequired,
  lineItems: PropTypes.instanceOf(Array).isRequired,
  totalNumberOfLineItems: PropTypes.number.isRequired,
  selectedLineItem: PropTypes.string,

  exportBillOfMaterials: PropTypes.func.isRequired,
  filterLineItemsByCostStatus: PropTypes.func.isRequired,
  listLineItems: PropTypes.func.isRequired,
  selectLineItem: PropTypes.func.isRequired
};

export default LineItemList;
