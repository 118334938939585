import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

import { ContextMenu, Panel, PanelBody, PanelFooter, PanelTitle } from 'apriori-react-core';

import {
  ARE_YOU_SURE,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE,
  NO_BILL_OF_MATERIALS_UPLOADED
} from '../../constants/messages';

import modal from '../../services/ModalService';
import Paginator from '../widgets/Paginator';

import BillOfMaterialsCard from './BillOfMaterialsCard';

class BillOfMaterialsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pageSize: 10
    };

    this.paginator = React.createRef();
  }

  componentDidMount() {
    const { listBillOfMaterials } = this.props;
    listBillOfMaterials();
  }

  delete = (billOfMaterials) => {
    const { deleteBillOfMaterials } = this.props;

    modal.openDeleteModal(
      ARE_YOU_SURE,
      ARE_YOU_SURE_YOU_WANT_TO_DELETE(billOfMaterials.name),
      () => {
        deleteBillOfMaterials(billOfMaterials.identity);
        modal.closeModal();
      }
    );
  };

  hasContent = () => {
    const { billOfMaterialsList } = this.props;
    return !!billOfMaterialsList && billOfMaterialsList.length > 0;
  };

  pageChanged = (pageNumber, pageSize) => {
    const { listBillOfMaterials } = this.props;

    this.setState({ pageSize });

    listBillOfMaterials(pageNumber, pageSize);
  };

  renderCards() {
    const { billOfMaterialsList, exportBillOfMaterials } = this.props;

    return billOfMaterialsList.map((billOfMaterials) => [
      <BillOfMaterialsCard
        key={billOfMaterials.identity}
        billOfMaterials={billOfMaterials}
      />,
      <ContextMenu
        key={`context-menu-${billOfMaterials.identity}`}
        contextId={`bill-of-materials-${billOfMaterials.identity}`}
        items={[
          {
            icon: faDownload,
            label: 'Export',
            onClick: () => exportBillOfMaterials(billOfMaterials.identity) },
          {
            icon: faTrash,
            label: 'Delete',
            onClick: () => this.delete(billOfMaterials) }
        ]}
      />
    ]);
  }

  render() {
    const { listBillOfMaterialsPending, title, totalNumberOfBillOfMaterials } = this.props;

    return (
      <Panel>
        <PanelTitle>
          <div className='title-left'>
            {title}
          </div>
        </PanelTitle>
        <PanelBody
          hasContent={this.hasContent()}
          isLoading={listBillOfMaterialsPending}
          noContentMessage={NO_BILL_OF_MATERIALS_UPLOADED}
        >
          {this.renderCards()}
        </PanelBody>
        <PanelFooter>
          <Paginator
            ref={this.paginator}
            pageChangedCallback={this.pageChanged}
            pageSizeOptions={[ 10, 15, 20 ]}
            totalItemCount={totalNumberOfBillOfMaterials}
          />
        </PanelFooter>
      </Panel>
    );
  }
}

BillOfMaterialsList.propTypes = {
  billOfMaterialsList: PropTypes.instanceOf(Array).isRequired,
  deleteBillOfMaterials: PropTypes.func.isRequired,
  exportBillOfMaterials: PropTypes.func.isRequired,
  listBillOfMaterials: PropTypes.func.isRequired,
  listBillOfMaterialsPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  totalNumberOfBillOfMaterials: PropTypes.number.isRequired
};

export default BillOfMaterialsList;
