import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UncontrolledTooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as farCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import {
  COSTED,
  INCOMPLETE,
  INCOMPLETE_MSG,
  INCOMPLETE_MULTIPLE_MATCHES_MSG,
  NO_PARTS_MATCHED,
  NO_PARTS_MATCHED_MSG,
  NOT_COSTED,
  READY_FOR_EXPORT,
  READY_FOR_EXPORT_MSG

} from '../../constants/messages';

class StatusIcon extends Component {

  render() {
    const { isMultipleMatches, status, uniqueId } = this.props;

    const statusIconId = `status-dot-${uniqueId}`;

    let statusIcon;
    let statusIconClasses;
    let tooltipMessage;

    switch (status) {
      case NO_PARTS_MATCHED:
        statusIcon = faTimesCircle;
        statusIconClasses = 'status-icon red';
        tooltipMessage = NO_PARTS_MATCHED_MSG;
        break;

      case INCOMPLETE:
        if (isMultipleMatches) {
          statusIcon = faQuestionCircle;
          statusIconClasses = 'status-icon blue';
          tooltipMessage = INCOMPLETE_MULTIPLE_MATCHES_MSG;
        } else {
          statusIcon = faExclamationCircle;
          statusIconClasses = 'status-icon yellow';
          tooltipMessage = INCOMPLETE_MSG;
        }
        break;

      case NOT_COSTED:
        statusIcon = faExclamationCircle;
        statusIconClasses = 'status-icon yellow';
        tooltipMessage = NOT_COSTED;
        break;

      case COSTED:
        statusIcon = farCheckCircle;
        statusIconClasses = 'status-icon blue';
        tooltipMessage = COSTED;
        break;

      case READY_FOR_EXPORT:
        statusIcon = faCheckCircle;
        statusIconClasses = 'status-icon green';
        tooltipMessage = READY_FOR_EXPORT_MSG;
        break;

      default:
        throw new Error(`The provided costing status '${status}' is not valid`);
    }

    return ([
      <FontAwesomeIcon
        key={statusIconId}
        id={statusIconId}
        icon={statusIcon}
        className={statusIconClasses}
      />,
      <UncontrolledTooltip
        key='line-item-list-filter-tooltip'
        placement='top'
        target={statusIconId}
      >
        {tooltipMessage}
      </UncontrolledTooltip>
    ]);
  }
}

StatusIcon.propTypes = {
  isMultipleMatches: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired
};

export default StatusIcon;
