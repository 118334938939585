import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle
} from 'reactstrap';

import {
  ASK_FOR_SELECTION,
  INCOMPLETE,
  NO_PARTS_MATCHED,
  NUMBER_OF_OPTIONS
} from '../../constants/messages';

import StatusIcon from '../widgets/StatusIcon';

class LineItemCard extends Component {

  cardClasses = () => this.isSelected() ? 'line-item-card selected' : 'line-item-card';

  cardClicked = (event) => {
    const identity = event.currentTarget.id;
    this.props.selectLineItem(identity);
  };

  isSelected = () => {
    const { lineItem, selectedLineItem } = this.props;
    return selectedLineItem ? selectedLineItem.includes(lineItem.identity) : false;
  };

  partSelectedForExport = () => this.props.lineItem.parts.filter(part => part.isReadyForExport)[0];

  renderAskToSelect() {
    const { lineItem } = this.props;
    return (
      <div className='ask-for-selection'>
        {((lineItem.status === INCOMPLETE || lineItem.status === NO_PARTS_MATCHED) 
          && lineItem.parts.length > 1) 
          ? ASK_FOR_SELECTION 
          : ''}
      </div>
    );
  }

  renderCostRollup() {
    if (this.partSelectedForExport()) {
      return <span className='text-muted number-parts'>${this.partSelectedForExport().averageCost}</span>;
    }
  }

  renderNumberOfParts() {
    const { lineItem } = this.props;

    return (
      <div>
        <div className='text-muted number-of-parts'>
          {lineItem.parts.length > 1 ? lineItem.parts.length + NUMBER_OF_OPTIONS : ''}
        </div>
      </div>
    );
  }

  renderSubtitle = () => {
    const { lineItem } = this.props;

    let message = <em>No manufacturer part number supplied</em>;

    if (lineItem.manufacturerPartNumber && lineItem.manufacturer) {
      message = `${lineItem.manufacturerPartNumber}, ${lineItem.manufacturer}`;
    }
    else if (lineItem.manufacturerPartNumber) {
      message = lineItem.manufacturerPartNumber;
    }

    return <small className='text-muted'>{message}</small>;
  };

  render() {
    const { lineItem } = this.props;

    return (
      <Card tag='a' className={this.cardClasses()} id={lineItem.identity} onClick={this.cardClicked}>
        <CardBody className='body-content'>
          <div className='body-left'>
          <CardTitle>
            <div className='title-left'>
              <StatusIcon status={lineItem.status} uniqueId={lineItem.identity} isMultipleMatches={lineItem.parts.length > 1} />
              {lineItem.customerPartNumber}
            </div>
            <div className='title-right'>
              {this.renderCostRollup()}
            </div>
          </CardTitle>
          <CardSubtitle>
            {this.renderSubtitle()}
          </CardSubtitle>
          </div>
          <div className='body-right'>
            {this.renderNumberOfParts()}
            {this.renderAskToSelect()}
          </div>
        </CardBody>
      </Card>
    );
  }
}

LineItemCard.defaultProps = {
  selectedLineItem: null
};

LineItemCard.propTypes = {
  lineItem: PropTypes.shape({
    parts: PropTypes.array
  }).isRequired,
  selectLineItem: PropTypes.func.isRequired,
  selectedLineItem: PropTypes.string
};

export default LineItemCard;
