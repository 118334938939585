import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'apriori-react-core';

import AccountForm from '../account/AccountForm';
import AccountList from '../../containers/AccountList';

class AccountWorkbench extends Component {

  componentDidMount() {
    this.props.listAccounts();
  }

  getSelectedAccount = () => this.props.accounts.find(account => account.identity === this.props.selectedAccount);

  isLoading = () =>
    this.props.activateAccountPending ||
    this.props.addAccountPending ||
    this.props.listAccountsPending ||
    this.props.updateAccountPending ||
    this.props.refreshLicensePending;

  renderLoadingOverlay() {
    if (this.isLoading()) {
      return <Loader key='loading-panel' />;
    }
  }

  render() {
    return ([
      this.renderLoadingOverlay(),
      <div key='accounts-list' className='col-6 full-height'>
        <AccountList />
      </div>,
      <div key='account-form' className='col-6 full-height'>
        <AccountForm
          account={this.getSelectedAccount()}
          addAccount={this.props.addAccount}
          updateAccount={this.props.updateAccount}
          refreshLicense={this.props.refreshLicense}
        />
      </div>
    ]);
  }
}

AccountWorkbench.defaultProps = {
  selectedAccount: null
};

AccountWorkbench.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  activateAccountPending: PropTypes.bool.isRequired,
  addAccountPending: PropTypes.bool.isRequired,
  addAccount: PropTypes.func.isRequired,
  listAccounts: PropTypes.func.isRequired,
  listAccountsPending: PropTypes.bool.isRequired,
  updateAccount: PropTypes.func.isRequired,
  updateAccountPending: PropTypes.bool.isRequired,
  refreshLicense: PropTypes.func.isRequired,
  refreshLicensePending: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.string
};

export default AccountWorkbench;
