import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_PENDING,
  AUTHENTICATION_SUCCEEDED,
  USER_LOGGED_OUT,
  SESSION_CHECK_INITIATED
} from '../constants/actions';

import authenticationService from '../services/AuthenticationService';

export const checkSession = () => (dispatch) => {
  dispatch({ type: SESSION_CHECK_INITIATED });
};

export const handleAuthentication = (callback) => (dispatch) => {
  authenticationService.handleAuthentication()
    .then(() => {
      dispatch({ type: AUTHENTICATION_SUCCEEDED });
      dispatch(checkSession());
      return callback();
    })
    .catch(error => {
      dispatch({ type: AUTHENTICATION_FAILED });
      dispatch(logOut());
      console.log(error);
      return callback(error);
    });
};

export const logIn = () => (dispatch) => {
  dispatch({ type: AUTHENTICATION_PENDING });
  authenticationService.signIn();
};

export const logOut = () => (dispatch) => {
  authenticationService.signOut();
  dispatch({ type: USER_LOGGED_OUT });
};
