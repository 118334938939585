/**
 * The following polyfills MUST be specified at the top of this file
 * before anything else and MUST be specified in this order.
 *
 * These polyfills provide support for IE11 and can be found here:
 * - https://www.npmjs.com/package/react-app-polyfill
 * - https://www.npmjs.com/package/babel-polyfill
 */
import 'react-app-polyfill/ie11';
import 'babel-polyfill';

import React from 'react';

import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { EnvironmentHelpers } from 'apriori-react-core';

import App from './components/App';
import ConfirmationModal from './components/widgets/ConfirmationModal';
import configureStore from './configureStore';
import history from './history'
import * as serviceWorker from './serviceWorker';

import './styles/theme.scss';

const EventEmitter = require('events');

const initialState = {};

// Create and export an event emitter to allow react components
// to emit events as an alternative to global state changes.
export const eventEmitter = new EventEmitter();

// Create and export the store to allow non react components
// to dispatch actions.
export const store = configureStore(initialState, history);

EnvironmentHelpers.checkEnvironment(
  'REACT_APP_AUTH0_CALLBACK_URL',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_LOGOUT_URL',
  'REACT_APP_SUPPORTING_API_URL'
);

render(
  <Provider store={store}>
    {/* <LanguageProvider messages={messages}> */}
    <ConfirmationModal />
    <ConnectedRouter history={history}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ConnectedRouter>
    {/* </LanguageProvider> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
