import { ValidationHelpers } from 'apriori-react-core';

export const validateAccountId = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Account ID');
  if (!error) error = ValidationHelpers.hasMinimumLength(value, 2, 'Account ID');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Account ID');
  return error;
};

export const validateAccountName = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Account Name');
  if (!error) error = ValidationHelpers.hasMinimumLength(value, 2, 'Account Name');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Account Name');
  return error;
};

export const validateAccountSecret = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Account Secret');
  if (!error) error = ValidationHelpers.hasMinimumLength(value, 2, 'Account Secret');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Account Secret');
  return error;
};
