import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'apriori-react-core';

import BillOfMaterialsList from '../../containers/BillOfMaterialsList';
import BillOfMaterialsUpload from '../../containers/BillOfMaterialsUpload';
import { CHECKING_YOUR_ACCOUNTS } from '../../constants/messages';
import { ROUTE_ACCOUNTS } from '../../constants/routes';

class Home extends Component {

  componentDidMount() {
    const { getActiveAccount, history } = this.props;
    getActiveAccount()
      .then((response) => {
        if (!response.response.isActive) {
          history.push(ROUTE_ACCOUNTS);
        }
      })
      .catch(() => {
        history.push(ROUTE_ACCOUNTS);
      });
  }
  
  isLoading() {
    const {
      deleteBillOfMaterialsPending,
      exportBillOfMaterialsPending,
      uploadBillOfMaterialsPending
    } = this.props;

    return deleteBillOfMaterialsPending ||
      exportBillOfMaterialsPending ||
      uploadBillOfMaterialsPending;
  }

  renderLoadingOverlay() {
    if (this.props.activeAccountPending) {
      return <Loader key='loading-panel' message={CHECKING_YOUR_ACCOUNTS} />;
    }
    if (this.isLoading()) {
      return <Loader key='loading-panel' />;
    }
  }

  render() {
    return ([
      this.renderLoadingOverlay(),
      <div key='home-upload-pane' className='col-6 full-height'>
        <BillOfMaterialsUpload />
      </div>,
      <div key='home-bill-of-materials-view-pane' className='col-6 full-height'>
        <div id='qa-uploaded-bill-of-materials' className='col-12 full-height'>
          <BillOfMaterialsList title='Uploaded Bill of Materials' />
        </div>
      </div>
    ]);
  };
}

Home.propTypes = {
  activeAccountPending: PropTypes.bool.isRequired,
  deleteBillOfMaterialsPending: PropTypes.bool.isRequired,
  exportBillOfMaterialsPending: PropTypes.bool.isRequired,
  getActiveAccount: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  uploadBillOfMaterialsPending: PropTypes.bool.isRequired
};

export default Home;
