import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import account from './account-reducer';
import authentication from './authentication-reducer';
import billOfMaterials from './bill-of-materials-reducer';
import lineItem from './line-item-reducer';
import parts from './part-reducer';

export default (history) => combineReducers({

  account,
  authentication,
  billOfMaterials,
  lineItem,
  parts,
  router: connectRouter(history)

});
