import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route } from 'react-router';

import { Loader } from 'apriori-react-core';

import { ROUTE_LOGIN } from '../../constants/routes';

import authenticationService from '../../services/AuthenticationService';

class PrivateRoute extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticationPending: true
    };
  }

  async componentDidMount() {
    const isAuthenticated = await authenticationService.isAuthenticated();
    this.setState({ isAuthenticated, isAuthenticationPending: false });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { isAuthenticated, isAuthenticationPending } = this.state;

    if (isAuthenticationPending) {
      return <Loader key='loading-panel' />;
    }

    return (
      <Route
        {...rest}
        render={props => isAuthenticated
          ? <Component {...props} />
          : <Redirect to={{ pathname: ROUTE_LOGIN, state: { from: props.location } }} />}
      />
    );
  }
}

PrivateRoute.defaultProps = {
  location: null
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object)
};

export default PrivateRoute;
