import React, { Component } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Actions, Messages } from 'apriori-react-core';

import { eventEmitter } from '../../index';

class ConfirmationModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cancelButtonText: Messages.CANCEL,
      confirmButtonText: Messages.CONFIRM,
      color: null,
      icon: null,
      isOpen: false,
      message: null,
      onCancel: this.close,
      onConfirm: this.close,
      title: null
    };
  }

  componentDidMount() {
    eventEmitter.addListener(Actions.CONFIRMATION_MODAL_CLOSED, () => {
      this.close();
    });
    eventEmitter.addListener(Actions.CONFIRMATION_MODAL_OPENED, options => {
      this.open(options);
    });
  }

  componentWillUnmount() {
    eventEmitter.removeListener(Actions.CONFIRMATION_MODAL_CLOSED, () => {});
    eventEmitter.removeListener(Actions.CONFIRMATION_MODAL_OPENED, () => {});
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  open = (options) => {
    this.setState({ ...options, isOpen: true });
  };

  render() {
    const {
      cancelButtonText,
      confirmButtonText,
      color,
      icon,
      isOpen,
      message,
      onCancel,
      onConfirm,
      title
    } = this.state;
    
    return (
      <Modal className='confirmation-modal' isOpen={isOpen} toggle={onCancel}>
        <ModalHeader toggle={onCancel} />
        <ModalBody>
          <FontAwesomeIcon icon={icon} className={color} />
          <h2>{title}</h2>
          <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button color={color} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
