import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown
} from 'reactstrap';

import { Checkmark, Crossmark, FormatHelpers, Panel, PanelBody, PanelFooter, PanelTitle } from 'apriori-react-core';

import {
  validateAccountId,
  validateAccountName,
  validateAccountSecret
} from '../../helpers/account-validation-helper';

import {
  ADD_NEW_ACCOUNT,
  INVALID_ACCOUNT_CREDENTIALS,
  LICENSE_REFRESH_INFO,
  LICENSE_REFRESH_OUTDATED,
  LICENSE_REFRESH_WARNING,
  UNABLE_TO_RENDER_FORM,
  VALID_ACCOUNT_CREDENTIALS
} from '../../constants/messages';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const initialState = {
  accountId: '',
  accountName: '',
  accountSecret: '',
  accountType: 'Silicon Expert',
  licenseLimit: '',
  licenseUsage: '',
  licenseRefreshedAt: '',
  validFrom: '',
  validTo: '',
  formErrors: {
    accountId: '',
    accountName: '',
    accountSecret: ''
  },
  formValidation: {
    accountId: false,
    accountName: false,
    accountSecret: false
  },
  isDirty: false,
  panelTitle: ADD_NEW_ACCOUNT
};

const UNLIMITED_LICENSE = -1;

class AccountForm extends Component {

  constructor(props) {
    super(props);
    this.state = Object.assign({}, initialState);
  };

  componentWillReceiveProps(nextProps) {
    const { account } = nextProps;

    let newState = Object.assign({}, initialState);

    if (account && account.identity) {
      newState = Object.assign(
        newState,
        {
          accountId: account.accountId,
          accountName: account.name,
          accountSecret: account.accountSecret,
          accountType: account.type,
          licenseLimit: account.licenseLimit,
          licenseUsage: account.licenseUsage,
          licenseRefreshedAt: account.licenseRefreshedAt,
          validFrom: account.validFrom,
          validTo: account.validTo,
          panelTitle: `Edit ${account.name}`
        }
      );

      // `setState` is asynchronous by nature therefore
      // perform validation in the `setState` callback
      // to ensure the state has been set before validating the form.
      this.setState(newState, () => this.validateForm());
    }
    else {
      this.setState(newState);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, isDirty: true });
    this.validateFormField(name, value);
  };

  isFormDirty = () => this.state.isDirty;

  isFormValid = () => {
    const { formValidation } = this.state;
    return (
      formValidation.accountId &&
      formValidation.accountName &&
      formValidation.accountSecret
    );
  };

  save = () => {
    const { identity } = this.props.account;

    const account = {
      accountId: this.state.accountId,
      name: this.state.accountName,
      accountSecret: this.state.accountSecret,
      type: this.state.accountType
    };

    if (identity) {
      this.props.updateAccount(identity, account);
    }
    else {
      this.props.addAccount(account);
    }
  };

  refreshLicense = () => {
    const { identity } = this.props.account;

    if (identity) {
      this.props.refreshLicense(identity);
    }
  };

  selectAccountType = (event) => {
    this.setState({ accountType: event.currentTarget.textContent });
  };

  validateForm = () => {
    this.validateFormField('accountId', this.state.accountId);
    this.validateFormField('accountName', this.state.accountName);
    this.validateFormField('accountSecret', this.state.accountSecret);
  };

  validateFormField = (name, value) => {
    let error;

    switch(name) {
      case 'accountId':
        error = validateAccountId(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, accountId: error },
          formValidation: { ...prevState.formValidation, accountId: !error }
        }));
        break;

      case 'accountName':
        error = validateAccountName(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, accountName: error },
          formValidation: { ...prevState.formValidation, accountName: !error }
        }));
        break;

      case 'accountSecret':
        error = validateAccountSecret(value);
        this.setState(prevState => ({
          formErrors: { ...prevState.formErrors, accountSecret: error },
          formValidation: { ...prevState.formValidation, accountSecret: !error }
        }));
        break;

      default:
        break;
    }
  };

  renderAccountCredentialSummary() {
    const { account } = this.props;

    if (!account || !account.identity) return;

    if (account.isValid) {
      return <span className='credential-summary green'><Checkmark />{VALID_ACCOUNT_CREDENTIALS}</span>;
    }

    return <span className='credential-summary red'><Crossmark />{INVALID_ACCOUNT_CREDENTIALS}</span>;
  }

  renderLicenseRefreshSummary() {
    const { account } = this.props;

    if (!account || !account.identity || !account.licenseLimit) return;

    if (!account.licenseRefreshedAt) {
      return <span className='credential-summary yellow'>
        <FontAwesomeIcon
            icon={faExclamationCircle}
            className='status-icon yellow form-message-warning'
        />
        {LICENSE_REFRESH_WARNING}
      </span>;
    } else if (moment().subtract(1, 'week').isAfter(moment(account.licenseRefreshedAt, "DD/MM/YYYY hh:mm"))) {
      return <span className='credential-summary yellow'>
        <FontAwesomeIcon
            icon={faExclamationCircle}
            className='status-icon yellow form-message-warning'
        />
        {LICENSE_REFRESH_OUTDATED(account.licenseRefreshedAt)}
      </span>;
    } else {
      return <span className='credential-summary green'><Checkmark/>{LICENSE_REFRESH_INFO}{account.licenseRefreshedAt}</span>;
    }
  }

  renderForm() {
    const {
      accountId,
      accountName,
      accountSecret,
      accountType,
      licenseLimit,
      licenseUsage,
      validFrom,
      validTo
    } = this.state;

    return (
      <Form key='add-account-form' className='account-form full-width'>
        <FormGroup>
          <Label className='form-label required'>Account Name</Label>
          <Input
            type='text'
            name='accountName'
            id='account-name'
            placeholder='Account Name'
            value={accountName}
            className={(this.state.formErrors.accountName ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{this.state.formErrors.accountName}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='form-label required'>Account Type</Label>
          <UncontrolledDropdown className='dropdown-outline' size='sm'>
            <DropdownToggle caret>
              {accountType}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.selectAccountType}>Silicon Expert</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </FormGroup>
        <FormGroup>
          <Label className='form-label required'>Account ID</Label>
          <Input
            type='text'
            name='accountId'
            id='account-id'
            placeholder='Account ID'
            value={accountId}
            className={(this.state.formErrors.accountId ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{this.state.formErrors.accountId}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='form-label required'>Account Secret</Label>
          <Input
            type='Password'
            name='accountSecret'
            id='account-secret'
            placeholder='Account Secret'
            value={accountSecret}
            className={(this.state.formErrors.accountSecret ? 'is-invalid' : '')}
            onChange={this.handleChange}
          />
          <FormFeedback>{this.state.formErrors.accountSecret}</FormFeedback>
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>License Limit</Label>
              <Input
                type='text'
                name='licenseLimit'
                id='license-limit'
                value={licenseLimit === UNLIMITED_LICENSE ? 'Unlimited' : licenseLimit}
                onChange={this.handleChange}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>License Usage</Label>
              <Input
                type='number'
                name='licenseUsage'
                id='license-usage'
                value={licenseUsage || ''}
                onChange={this.handleChange}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>Valid from</Label>
              <Input
                type='text'
                name='validFrom'
                id='valid-from'
                value={FormatHelpers.formatAsShortDate(validFrom)}
                onChange={this.handleChange}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='form-label'>Valid to</Label>
              <Input
                type='text'
                name='validTo'
                id='valid-to'
                value={FormatHelpers.formatAsShortDate(validTo)}
                onChange={this.handleChange}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        {this.renderAccountCredentialSummary()}
        {this.renderLicenseRefreshSummary()}
      </Form>
    );
  }

  render() {
    const { identity } = this.props.account;
    let buttonRefreshLicense;
    if (identity) {
      buttonRefreshLicense = <Button
          outline
          color='primary'
          onClick={this.refreshLicense}
          disabled={!this.isFormValid() || this.isFormDirty()}
      >
        Refresh license info
      </Button>;
    }

    return (
      <Panel className='account-form-panel'>
        <PanelTitle>
          <div className='title-left'>
            {this.state.panelTitle}
          </div>
        </PanelTitle>
        <PanelBody noContentMessage={UNABLE_TO_RENDER_FORM}>
          {this.renderForm()}
        </PanelBody>
        <PanelFooter>
          <Button
            outline
            color='primary'
            onClick={this.save}
            disabled={!this.isFormValid() || !this.isFormDirty()}
          >
            Save
          </Button>
          {buttonRefreshLicense}
        </PanelFooter>
      </Panel>
    );
  }
}

AccountForm.defaultProps = {
  account: {}
};

AccountForm.propTypes = {
  account: PropTypes.instanceOf(Object),
  addAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  refreshLicense: PropTypes.func.isRequired
};

export default AccountForm;
