import { ValidationHelpers } from 'apriori-react-core';

export const validateAvailability = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'Availability');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Availability');
  return error;
};

export const validateAverageCost = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Average Cost');
  if (!error) error = ValidationHelpers.greaterThanZero(value, 'Average Cost');
  if (!error) error = ValidationHelpers.hasMaximumDecimalPlaces(value, 4, 'Average Cost');
  return error;
};

export const validateClassification = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'Classification');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Classification');
  return error;
};

export const validateDataSheetUrl = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 10, 'DataSheet URL');
  if (!error) error = ValidationHelpers.isUrl(value, 'DataSheet URL');
  return error;
};

export const validateDescription = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'Description');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Description');
  return error;
};

export const validateManufacturer = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'Manufacturer');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Manufacturer');
  return error;
};

export const validateManufacturerPartNumber = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Manufacturer Part Number');
  if (!error) error = ValidationHelpers.hasMinimumLength(value, 2, 'Manufacturer Part Number');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'Manufacturer Part Number');
  return error;
};

export const validateMinimumCost = (value) => {
  let error = ValidationHelpers.greaterThanZero(value, 'Minimum Cost');
  if (!error) error = ValidationHelpers.hasMaximumDecimalPlaces(value, 4, 'Minimum Cost');
  return error;
};

export const validateMountType = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Mount Type');
  if (!error) error = ValidationHelpers.hasMinimumLength(value, 5, 'Mount Type');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 64, 'Mount Type');
  return error;
};

export const validatePinCount = (value) => {
  let error = ValidationHelpers.isRequired(value, 'Pin Count');
  if (!error) error = ValidationHelpers.greaterThanZero(value, 'Pin Count');
  if (!error) error = ValidationHelpers.isWholeNumber(value, 'Pin Count');
  return error;
};

export const validateRohs = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'RoHS');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'RoHS');
  return error;
};

export const validateRohsVersion = (value) => {
  let error = ValidationHelpers.hasMinimumLength(value, 2, 'RoHS Version');
  if (!error) error = ValidationHelpers.hasMaximumLength(value, 255, 'RoHS Version');
  return error;
};
