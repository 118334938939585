import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';

class Paginator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      selectedPageSize: this.props.pageSizeOptions[0]
    };
  }

  decrementPageNumber = () => {
    let nextPageNumber = this.state.currentPage - 1;
    if (nextPageNumber < 1) {
      nextPageNumber = 1;
    }

    this.setPageNumber(nextPageNumber);
    this.props.pageChangedCallback(nextPageNumber, this.state.selectedPageSize);
  };

  incrementPageNumber = () => {
    let nextPageNumber = this.state.currentPage + 1;
    if (nextPageNumber > this.numberOfPages()) {
      nextPageNumber = this.numberOfPages();
    }

    this.setPageNumber(nextPageNumber);
    this.props.pageChangedCallback(nextPageNumber, this.state.selectedPageSize);
  };

  leftArrowDisabled = () => this.state.currentPage === 1;

  numberOfPages = () =>
    Math.floor((this.props.totalItemCount + this.state.selectedPageSize - 1) / this.state.selectedPageSize);

  rightArrowDisabled = () => this.numberOfPages() === 0 ? true : this.state.currentPage === this.numberOfPages();

  selectPageSize = (e) => {
    const pageSize = parseInt(e.currentTarget.value, 10);
    this.setState({ currentPage: 1, selectedPageSize: pageSize });
    this.props.pageChangedCallback(1, pageSize);
  };

  setPageNumber = (pageNumber) => this.setState({ currentPage: pageNumber });

  renderPageSizeDropdown() {
    const pageSizeOptions = this.props.pageSizeOptions.map(pageSize => (
      <DropdownItem
        key={pageSize}
        onClick={this.selectPageSize}
        value={pageSize}
      >
        {pageSize}
      </DropdownItem>
      ));

    return (
      <UncontrolledDropdown className='dropdown-outline' size='sm'>
        <DropdownToggle caret>
          {this.state.selectedPageSize}
        </DropdownToggle>
        <DropdownMenu>
          {pageSizeOptions}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderPagingSummary() {
    const { totalItemCount } = this.props;
    const { currentPage, selectedPageSize } = this.state;

    if (totalItemCount === 0) {
      return '0 - 0 of 0';
    }

    const from = (currentPage * selectedPageSize) - (selectedPageSize - 1);
    const to = Math.min(from + selectedPageSize - 1, totalItemCount);

    return `${from} - ${to} of ${totalItemCount}`;
  }

  render() {
    return (
      <div className='paginator'>
        <div id='qa-page-size-dropdown' className='left'>
          {this.renderPageSizeDropdown()}
        </div>
        <div className='right'>
          <span id='qa-summary' className='summary'>
            {this.renderPagingSummary()}
          </span>
          <div className='control'>
            <i
              id='arrow-left'
              className='arrow left'
              disabled={this.leftArrowDisabled()}
              onClick={this.decrementPageNumber}
            />
            <UncontrolledTooltip
              placement='top'
              target='arrow-left'
            >
              Previous page
            </UncontrolledTooltip >
            <i
              id='arrow-right'
              className='arrow right'
              disabled={this.rightArrowDisabled()}
              onClick={this.incrementPageNumber}
            />
            <UncontrolledTooltip
              placement='top'
              target='arrow-right'
            >
              Next page
            </UncontrolledTooltip >
          </div>
        </div>
      </div>
    );
  }
}

Paginator.propTypes = {
  pageChangedCallback: PropTypes.func.isRequired,
  pageSizeOptions: PropTypes.instanceOf(Array).isRequired,
  totalItemCount: PropTypes.number.isRequired
};

export default Paginator;
