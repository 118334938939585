import { createStore, applyMiddleware, compose } from 'redux';

import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { EnvironmentHelpers } from 'apriori-react-core';

import createRootReducer from './reducers';

export default function configureStore(initialState = {}, history) {

  //
  // Create the store with the following middleware:
  // 1. `redux-thunk``:
  //        With a plain basic Redux store, you can only do simple
  //        synchronous updates by dispatching an action.
  //        Middleware extends the store's abilities,
  //        and allows you to write async logic that interacts with the store.
  // 2. `connected-react-router`:
  //        Synchronize router state with redux store through uni-directional flow
  //        and support time traveling using Redux DevTools.
  //
  const middleware = [thunk, routerMiddleware(history)];

  if (isNotProduction()) {
    middleware.push(createLogger());
  }

  const enhancers = [applyMiddleware(...middleware)];
  const composeEnhancers = shouldUseReduxDevtools()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

  const store = createStore(createRootReducer(history), initialState, composeEnhancers(...enhancers));
  store.injectedReducers = {};

  return store;
}

const isNotProduction = () => EnvironmentHelpers.getEnvironmentVariable('NODE_ENV') !== 'production';

const shouldUseReduxDevtools = () => isNotProduction() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
