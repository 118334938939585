import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { ROUTE_BILL_OF_MATERIALS } from '../../constants/routes';
import BillOfMaterialsRemainingWork from './BillOfMaterialsRemainingWork';

class BillOfMaterialsCard extends Component {

  getLink = () => `${ROUTE_BILL_OF_MATERIALS}/${this.props.billOfMaterials.identity}`;

  render() {
    const { billOfMaterials } = this.props;

    return (
      <Link
        to={this.getLink()}
        id={`bill-of-materials-${billOfMaterials.identity}`}
        className='bill-of-materials-card-wrapper not-link'
      >
        <Card className='bill-of-materials-card'>
          <CardBody>
            <div className='card-text-left'>
              <div id='qa-bill-of-materials-type' className={`bill-of-materials-type ${billOfMaterials.type.toLowerCase()} mr-3`}>
                {billOfMaterials.type}
              </div>
              <div className='info'>
                <span id='qa-bill-of-materials-name'>
                  {billOfMaterials.name}
                </span>
                <small id='qa-bill-of-materials-file-name' className='text-muted'>
                  {billOfMaterials.filename}
                </small>
              </div>
            </div>
            <div className='card-text-right'>
              {
                billOfMaterials.updatedAt &&
                <div className='property'>
                  <span>Last updated </span>
                  <Moment fromNow>{billOfMaterials.updatedAt}</Moment>
                </div>
              }
              <div className='property'>
                Uploaded <Moment fromNow>{billOfMaterials.createdAt}</Moment>
              </div>
              <div id='qa-bill-of-materials-number-0f-line-items' className='property'>
                <span>{billOfMaterials.totalNumberOfLineItems} </span>
                <span>line items</span>
              </div>
            </div>
            <BillOfMaterialsRemainingWork billOfMaterials={billOfMaterials} isMinimized />
          </CardBody>
        </Card>
      </Link>
    );
  }
}

BillOfMaterialsCard.propTypes = {
  billOfMaterials: PropTypes.instanceOf(Object).isRequired
};

export default BillOfMaterialsCard;
