import { connect } from 'react-redux';

import {
  addCustomPart,
  clearPartSelection,
  costParts,
  getAttributeDictionary,
  selectPart,
  selectPartForExport,
  updatePart
} from '../actions/part-actions';

import PartList from '../components/part/PartList';

const mapStateToProps = (state) => ({
  billOfMaterials: state.billOfMaterials.billOfMaterials,
  lineItems: state.lineItem.lineItems,
  selectedLineItem: state.lineItem.selectedLineItem,
  selectedParts: state.parts.selectedParts,
  attributesDictionary: state.parts.attributesDictionary
});

const mapDispatchToProps = (dispatch) => ({
  addPart: (billOfMaterialsIdentity, lineItemIdentity, part) =>
    dispatch(addCustomPart(billOfMaterialsIdentity, lineItemIdentity, part)),
  getAttributeDictionary: (type) => dispatch(getAttributeDictionary(type)),
  clearPartSelection: () => dispatch(clearPartSelection()),
  costParts: (billOfMaterialsIdentity, lineItemIdentity, partIdentities) =>
    dispatch(costParts(billOfMaterialsIdentity, lineItemIdentity, partIdentities)),
  updatePart: (billOfMaterialsIdentity, lineItemIdentity, partIdentity, part) =>
    dispatch(updatePart(billOfMaterialsIdentity, lineItemIdentity, partIdentity, part)),
  selectPart: (identity) => dispatch(selectPart(identity)),
  selectPartForExport: (billOfMaterialsIdentity, lineItemIdentity, partIdentity) =>
    dispatch(selectPartForExport(billOfMaterialsIdentity, lineItemIdentity, partIdentity))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartList);
