import { connect } from 'react-redux';

import {
  filterLineItemsByCostStatus,
  listLineItems,
  searchLineItems,
  selectLineItem,
  resetFilter
} from '../actions/line-item-actions';

import LineItemList from '../components/line-item/LineItemList';
import {exportBillOfMaterials} from "../actions/bill-of-materials-actions";

const mapStateToProps = (state) => ({
  billOfMaterials: state.billOfMaterials.billOfMaterials,
  lineItems: state.lineItem.lineItems,
  totalNumberOfLineItems: state.lineItem.totalItemCount,
  selectedCostStatusFilterValue: state.lineItem.costStatus,
  selectedLineItem: state.lineItem.selectedLineItem
});

const mapDispatchToProps = (dispatch) => ({
  filterLineItemsByCostStatus: (status) => dispatch(filterLineItemsByCostStatus(status)),
  exportBillOfMaterials: (identity) => dispatch(exportBillOfMaterials(identity)),
  listLineItems: (billOfMaterialsIdentity, pageNumber, pageSize, searchTerm, costStatus) => {
    dispatch(listLineItems(billOfMaterialsIdentity, pageNumber, pageSize, searchTerm, costStatus))
  },
  searchLineItems: (searchTerm) => dispatch(searchLineItems(searchTerm)),
  selectLineItem: (identity) => dispatch(selectLineItem(identity)),
  resetFilter: () => dispatch(resetFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItemList);
