import { HttpRequestHelpers, LocalStorageService, User, Toast } from 'apriori-react-core';

import { checkSession } from '../actions/authentication-actions';
import { CURRENT_USER_URL } from '../constants/endpoints';
import { UNABLE_TO_DECODE_JWT, UNABLE_TO_RETRIEVE_USER_PROFILE } from '../constants/messages';
import { ROUTE_SOMETHING_WENT_WRONG } from '../constants/routes';
import history from '../history';
import { store } from '../index';

const jwtDecode = require('jwt-decode');

class AprioriAuthenticationService {

  tokenRenewalTimeout;

  /**
   * Construct this service as a `singleton`.
   * This will ensure correct session management.
   */
  constructor() {
    if (!AprioriAuthenticationService.aprioriAuthenticationService) {
      AprioriAuthenticationService.aprioriAuthenticationService = this;
    }

    return AprioriAuthenticationService.authenticationService;
  }

  clearLocalStorage = () => {
    LocalStorageService.removeAccessToken();
    LocalStorageService.removeAuthProvider();
    LocalStorageService.removeExpiresAt();
    LocalStorageService.removeIdToken();
  };

  getUserProfile = ()  => new Promise(resolve => {
    const headers = HttpRequestHelpers.getDefaultHeaders();
    const url = CURRENT_USER_URL;

    HttpRequestHelpers.get(url, headers)
      .then(response => resolve(new User(response.response)))
      .catch(() => {
        const errorMessage = UNABLE_TO_RETRIEVE_USER_PROFILE;
        Toast.error(errorMessage);
        console.log(errorMessage);
        resolve(null);
      })
  });

  renewSession = () => {
    // TODO (aj): Complete implementation.
    this.signOut();
  };

  /**
   * Based on the expiration time defined by `expiresAt`,
   * this will schedule a renewal for 5 seconds prior to the token expiring.
   */
  scheduleRenewal = () => {
    const timeout = LocalStorageService.getExpiresAt() - Date.now() - 5000;

    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
    else {
      this.renewSession();
    }
  };

  setSession = (expiresAt, idToken) => {
    LocalStorageService.setExpiresAt(expiresAt);
    LocalStorageService.setIdToken(idToken);

    this.scheduleRenewal();
  };

  signIn = (token) => {
    // TODO (aj): Complete implementation.
    try {
      const decodedToken = jwtDecode(token);
      const expiresAt = decodedToken.exp * 1000;
      this.setSession(expiresAt, token);

      // Dispatch a `SESSION_CHECK_INITIATED` action
      // to inform restricted components to re-render if necessary
      // now that the sign in flow is complete.
      store.dispatch(checkSession());
    }
    catch {
      // `jwtDecode(...)` will throw an error if the token is not valid.
      // Instead, silence this error and allow standard authentication process to continue.
      Toast.error(UNABLE_TO_DECODE_JWT);
      history.push(ROUTE_SOMETHING_WENT_WRONG);
    }
  };

  signOut = () => {
    this.clearLocalStorage();
    clearTimeout(this.tokenRenewalTimeout);
    window.location.reload();
  };
}

const aprioriAuthenticationService = new AprioriAuthenticationService();

export default aprioriAuthenticationService;
