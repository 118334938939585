import { connect } from 'react-redux';

import AccountWorkbench from '../components/workspace/AccountWorkbench';
import {addAccount, listAccounts, refreshLicense, updateAccount} from '../actions/account-actions';

const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  activateAccountPending: state.account.activateAccountRequestStatus.pending,
  addAccountPending: state.account.addAccountRequestStatus.pending,
  listAccountsPending: state.account.listAccountsRequestStatus.pending,
  updateAccountPending: state.account.updateAccountRequestStatus.pending,
  refreshLicensePending: state.account.refreshLicenseRequestStatus.pending,
  selectedAccount: state.account.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
  addAccount: (account) => dispatch(addAccount(account)),
  updateAccount: (identity, account) => dispatch(updateAccount(identity, account)),
  refreshLicense: (identity) => dispatch(refreshLicense(identity)),
  listAccounts: () => dispatch(listAccounts())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountWorkbench);
