import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader, ObjectHelpers, Panel } from 'apriori-react-core';

import LineItemList from '../../containers/LineItemList';
import PartList from '../../containers/PartList';

import BillOfMaterialsRemainingWork from '../bill-of-materials/BillOfMaterialsRemainingWork';

class BillOfMaterialsWorkbench extends Component {

  constructor(props) {
    super(props);
    this.state = {
      identity: props.match.params.id
    };
  }

  componentDidMount() {
    this.props.getBillOfMaterials(this.state.identity, true);
  }

  isLoading() {
    const {
      costPartsPending,
      exportBillOfMaterialsPending,
      getBillOfMaterialsPending,
      listLineItemsPending
    } = this.props;

    return costPartsPending ||
      exportBillOfMaterialsPending ||
      getBillOfMaterialsPending ||
      listLineItemsPending;
  }

  renderLoadingOverlay() {
    if (this.isLoading()) {
      return <Loader key='loading-panel' />;
    }
  }

  getPartsFilteredBySelectedLineItem() {
    const { lineItems, selectedLineItem } = this.props;

    if (!selectedLineItem) {
      return [];
    }

    const lineItem = lineItems.find(x => x.identity === selectedLineItem);
    if (!lineItem) {
      return [];
    }

    return lineItem.parts;
  }

  render() {
    const { billOfMaterials } = this.props;
    const { identity } = this.state;

    if (ObjectHelpers.isEmpty(billOfMaterials) && !this.isLoading()) {
      return <h1>Could not find Bill of Materials with identity &apos;{identity}&apos;</h1>;
    }

    if (ObjectHelpers.isEmpty(billOfMaterials) && this.isLoading()) {
      return this.renderLoadingOverlay();
    }

    return ([
      this.renderLoadingOverlay(),
      <div key='workspace-left' className='col-4 full-height no-padding'>
        <div className='col-12 seven-eighth-height'>
          <LineItemList />
        </div>
        <div className='col-12 one-eighth-height'>
          <Panel>
            <div className='bill-of-materials-remaining-work-wrapper'>
              <BillOfMaterialsRemainingWork billOfMaterials={billOfMaterials} />
            </div>
          </Panel>
        </div>
      </div>,
      <div key='workspace-matched-parts-pane' className='col-8 full-height'>
        <PartList parts={this.getPartsFilteredBySelectedLineItem()} />
      </div>
    ]);
  };
}

BillOfMaterialsWorkbench.defaultProps = {
  selectedLineItem: null
};

BillOfMaterialsWorkbench.propTypes = {
  billOfMaterials: PropTypes.instanceOf(Object).isRequired,
  costPartsPending: PropTypes.bool.isRequired,
  exportBillOfMaterialsPending: PropTypes.bool.isRequired,
  getBillOfMaterials: PropTypes.func.isRequired,
  getBillOfMaterialsPending: PropTypes.bool.isRequired,
  listLineItemsPending: PropTypes.bool.isRequired,
  lineItems: PropTypes.instanceOf(Array).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  selectedLineItem: PropTypes.string
};

export default BillOfMaterialsWorkbench;
