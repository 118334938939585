export const attributeNamesDictionary = new Map([
    ["availability", "availability"],
    ["averageCost", "average_cost"],
    ["classification", "classification"],
    ["dataSheetUrl", "data_sheet_url"],
    ["description", "description"],
    ["manufacturer", "manufacturer"],
    ["manufacturerPartNumber", "manufacturer_part_number"],
    ["minimumCost", "minimum_cost"],
    ["mountType", "mount_type"],
    ["pinCount", "pin_count"],
    ["rohs", "rohs"],
    ["rohsVersion", "rohs_version"],
    ["externalId", "external_id"]
]);
