import { push } from 'connected-react-router';

import { HttpRequestHelpers } from 'apriori-react-core';

import {
  DELETE_BILL_OF_MATERIALS_FAILED,
  DELETE_BILL_OF_MATERIALS_PENDING,
  DELETE_BILL_OF_MATERIALS_SUCCEEDED,
  EXPORT_BILL_OF_MATERIALS_FAILED,
  EXPORT_BILL_OF_MATERIALS_PENDING,
  EXPORT_BILL_OF_MATERIALS_SUCCEEDED,
  GET_BILL_OF_MATERIALS_FAILED,
  GET_BILL_OF_MATERIALS_PENDING,
  GET_BILL_OF_MATERIALS_SUCCEEDED,
  LIST_BILL_OF_MATERIALS_FAILED,
  LIST_BILL_OF_MATERIALS_PENDING,
  LIST_BILL_OF_MATERIALS_SUCCEEDED,
  UPLOAD_BILL_OF_MATERIALS_FAILED,
  UPLOAD_BILL_OF_MATERIALS_PENDING,
  UPLOAD_BILL_OF_MATERIALS_SUCCEEDED
} from '../constants/actions';

import { BILL_OF_MATERIALS_URL } from '../constants/endpoints';
import { ROUTE_BILL_OF_MATERIALS } from '../constants/routes';

import { clearLineItem, listLineItems } from './line-item-actions';
import { clearPartSelection } from './part-actions';

export const deleteBillOfMaterials = (identity) => async (dispatch) => {

  dispatch(deleteBillOfMaterialsPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${BILL_OF_MATERIALS_URL}/${identity}`;

  return HttpRequestHelpers.$delete(url, headers)
    .then(() => {
      dispatch(deleteBillOfMaterialsSucceeded());
      dispatch(listBillOfMaterials());
    })
    .catch(error => {
      dispatch(deleteBillOfMaterialsFailed());
      console.log(error);
    });
};

export const exportBillOfMaterials = (identity) => async (dispatch) => {

  dispatch(exportBillOfMaterialsPending());

  const headers = {...HttpRequestHelpers.getDefaultHeaders(), 'Accept': 'text/csv'};
  const url = `${BILL_OF_MATERIALS_URL}/${identity}/export`;

  return HttpRequestHelpers.post(url, null, headers)
    .then(response => {
      dispatch(exportBillOfMaterialsSucceeded());
      return response;
    })
    .then(async response => {
      const blob = await response.blob();
      const filename = response.headers.get('Content-Disposition')
        .split('filename=')[1]
        .split('"')
        .join('');
      return { blob, filename }
    })
    .then(result => {
      const temporaryLink = document.createElement('a');
      temporaryLink.href = window.URL.createObjectURL(new Blob([result.blob]));
      temporaryLink.setAttribute('download', result.filename);

      document.body.appendChild(temporaryLink);
      temporaryLink.click();
      temporaryLink.parentNode.removeChild(temporaryLink);
    })
    .catch(error => {
      dispatch(exportBillOfMaterialsFailed());
      console.log(error);
    });
};

export const getBillOfMaterials = (identity, shouldResetLineItemPageNumber = false) => async (dispatch) => {

  dispatch(getBillOfMaterialsPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${BILL_OF_MATERIALS_URL}/${identity}`;

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch(getBillOfMaterialsSucceeded(response.response));

      if (shouldResetLineItemPageNumber) {
        dispatch(listLineItems(identity, 1));
      }
      else {
        dispatch(listLineItems(identity));
      }

      return response;
    })
    .catch(error => {
      dispatch(getBillOfMaterialsFailed());
      console.log(error);
    });
};

export const listBillOfMaterials = (pageNumber, pageSize) => async (dispatch, getState) => {

  // Use the current page number and page size from global state, if none are supplied.
  if (!pageNumber) {
    pageNumber = getState().lineItem.pageNumber;
  }
  if (!pageSize) {
    pageSize = getState().lineItem.pageSize;
  }

  dispatch(listBillOfMaterialsPending());
  dispatch(clearPartSelection());
  dispatch(clearLineItem());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${BILL_OF_MATERIALS_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`;

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch(listBillOfMaterialsSucceeded(response.response));
      return response;
    })
    .catch(error => {
      dispatch(listBillOfMaterialsFailed());
      console.log(error);
    });
};

export const uploadBillOfMaterials = (inputFile, type) => async (dispatch) => {

  dispatch(uploadBillOfMaterialsPending());

  const body = new FormData();
  body.append('multiPartFile', inputFile, inputFile.name);
  body.append('type', type);

  const headers = HttpRequestHelpers.getDefaultHeaders();
  delete headers['Content-Type'];
  const url = BILL_OF_MATERIALS_URL;

  return HttpRequestHelpers.post(url, body, headers)
    .then(response => {
      const { identity } = response.response;
      dispatch(uploadBillOfMaterialsSucceeded(response.response));
      dispatch(listLineItems(identity));
      dispatch(push(`${ROUTE_BILL_OF_MATERIALS}/${identity}`));
      return response;
    })
    .catch(error => {
      dispatch(uploadBillOfMaterialsFailed());
      console.log(error);
    });
};

const deleteBillOfMaterialsFailed = () => ({
  type: DELETE_BILL_OF_MATERIALS_FAILED,
  payload: null
});

const deleteBillOfMaterialsPending = () => ({
  type: DELETE_BILL_OF_MATERIALS_PENDING,
  payload: null
});

const deleteBillOfMaterialsSucceeded = () => ({
  type: DELETE_BILL_OF_MATERIALS_SUCCEEDED,
  payload: null
});

const exportBillOfMaterialsFailed = () => ({
  type: EXPORT_BILL_OF_MATERIALS_FAILED,
  payload: null
});

const exportBillOfMaterialsPending = () => ({
  type: EXPORT_BILL_OF_MATERIALS_PENDING,
  payload: null
});

const exportBillOfMaterialsSucceeded = () => ({
  type: EXPORT_BILL_OF_MATERIALS_SUCCEEDED,
  payload: null
});

const getBillOfMaterialsFailed = () => ({
  type: GET_BILL_OF_MATERIALS_FAILED,
  payload: null
});

const getBillOfMaterialsPending = () => ({
  type: GET_BILL_OF_MATERIALS_PENDING,
  payload: null
});

const getBillOfMaterialsSucceeded = billOfMaterials => ({
  type: GET_BILL_OF_MATERIALS_SUCCEEDED,
  payload: billOfMaterials
});

const listBillOfMaterialsFailed = () => ({
  type: LIST_BILL_OF_MATERIALS_FAILED,
  payload: null
});

const listBillOfMaterialsPending = () => ({
  type: LIST_BILL_OF_MATERIALS_PENDING,
  payload: null
});

const listBillOfMaterialsSucceeded = billOfMaterialsList => ({
  type: LIST_BILL_OF_MATERIALS_SUCCEEDED,
  payload: billOfMaterialsList
});

const uploadBillOfMaterialsFailed = () => ({
  type: UPLOAD_BILL_OF_MATERIALS_FAILED,
  payload: null
});

const uploadBillOfMaterialsPending = () => ({
  type: UPLOAD_BILL_OF_MATERIALS_PENDING,
  payload: null
});

const uploadBillOfMaterialsSucceeded = billOfMaterials => ({
  type: UPLOAD_BILL_OF_MATERIALS_SUCCEEDED,
  payload: billOfMaterials
});
