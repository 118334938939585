import { HttpRequestHelpers } from 'apriori-react-core';

import {
  LINE_ITEM_CLEARED,
  LINE_ITEM_COST_STATUS_FILTER_APPLIED,
  LINE_ITEM_FILTER_RESET,
  LINE_ITEM_SEARCH_APPLIED,
  LINE_ITEM_SELECTED,
  LIST_LINE_ITEMS_FAILED,
  LIST_LINE_ITEMS_PENDING,
  LIST_LINE_ITEMS_SUCCEEDED
} from '../constants/actions';

import { LINE_ITEMS_URL } from '../constants/endpoints';

import { clearPartSelection } from "./part-actions";

export const filterLineItemsByCostStatus = (status) => async (dispatch) => {
  dispatch(lineItemCostStatusFilterApplied(status));
};

export const listLineItems = (billOfMaterialsIdentity, pageNumber, pageSize, searchTerm, costStatus) => async (dispatch, getState) => {
  
  // Use the current page number and page size from global state, if none are supplied.
  if (!pageNumber) {
    pageNumber = getState().lineItem.pageNumber;
  }
  if (!pageSize) {
    pageSize = getState().lineItem.pageSize;
  }
  if (!costStatus ) {
    costStatus = getState().lineItem.costStatus;
  }
  if (!searchTerm) {
    searchTerm = getState().lineItem.searchTerm;
  }
  dispatch(listLineItemsPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  let url = `${LINE_ITEMS_URL(billOfMaterialsIdentity)}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  // TODO (aj): Enable when OR is implemented - `&customerPartNumber[CN]=${searchTerm}&manufacturer[CN]=${searchTerm}`
  if (searchTerm) {
    url += `&customerPartNumber[CN]=${searchTerm}`;
  }
  if (costStatus && costStatus!=='none') {
    url += `&status[EQ]=${costStatus}`;
  }

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch(listLineItemsSucceeded(
        costStatus,
        response.response.items,
        response.response.pageNumber,
        response.response.pageSize,
        searchTerm,
        response.response.totalItemCount));
      return response;
    })
    .catch(error => {
      dispatch(listLineItemsFailed());
      console.log(error);
    });
};

export const resetFilter = () => ({
  type: LINE_ITEM_FILTER_RESET
});

export const searchLineItems = (searchTerm) => async (dispatch) => {
  dispatch(lineItemSearchApplied(searchTerm));
};

export const selectLineItem = (identity) => (dispatch) => {
  dispatch(lineItemSelected(identity));
  dispatch(clearPartSelection());
};

export const clearLineItem = () => (dispatch) => {
  dispatch(lineItemCleared());
};

const lineItemCostStatusFilterApplied = (status) => ({
  type: LINE_ITEM_COST_STATUS_FILTER_APPLIED,
  payload: status
});

const lineItemSearchApplied = searchTerm => ({
  type: LINE_ITEM_SEARCH_APPLIED,
  payload: searchTerm
});

const lineItemSelected = identity => ({
  type: LINE_ITEM_SELECTED,
  payload: identity
});

const lineItemCleared = () => ({
  type: LINE_ITEM_CLEARED,
  payload: null
});

const listLineItemsFailed = () => ({
  type: LIST_LINE_ITEMS_FAILED,
  payload: null
});

const listLineItemsPending = () => ({
  type: LIST_LINE_ITEMS_PENDING,
  payload: null
});

const listLineItemsSucceeded = (costStatus, lineItems, pageNumber, pageSize, searchTerm, totalItemCount) => ({
  type: LIST_LINE_ITEMS_SUCCEEDED,
  costStatus,
  lineItems,
  pageNumber,
  pageSize,
  searchTerm,
  totalItemCount
});
