import {
  LINE_ITEM_SELECTED,
  LIST_LINE_ITEMS_FAILED,
  LIST_LINE_ITEMS_PENDING,
  LINE_ITEM_FILTER_RESET,
  LIST_LINE_ITEMS_SUCCEEDED,
  LINE_ITEM_CLEARED
} from "../constants/actions";

const initialState = {
  lineItems: [],
  listLineItemsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  costStatus: null,
  pageNumber: 1,
  pageSize: 10,
  searchTerm: null,
  selectedLineItem: null,
  totalItemCount: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case LINE_ITEM_FILTER_RESET: {
      return {
        ...state,
        costStatus: null,
        pageNumber: 1,
        pageSize: 10,
        searchTerm: null,
      };
    }

    case LINE_ITEM_SELECTED: {
      return {
        ...state,
        selectedLineItem: action.payload
      };
    }

    case LINE_ITEM_CLEARED: {
      return {
        ...state,
        selectedLineItem: null
      };
    }

    case LIST_LINE_ITEMS_FAILED: {
      const listLineItemsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        listLineItemsRequestStatus
      };
    }

    case LIST_LINE_ITEMS_PENDING: {
      const listLineItemsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        listLineItemsRequestStatus
      };
    }

    case LIST_LINE_ITEMS_SUCCEEDED: {
      const listLineItemsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        costStatus: action.costStatus,
        lineItems: action.lineItems,
        listLineItemsRequestStatus,
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
        totalItemCount: action.totalItemCount
      };
    }

    default: {
      return state;
    }

  }
}