import { connect } from 'react-redux';

import { logIn, logOut } from '../actions/authentication-actions';

import Login from '../components/authentication/Login';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logIn: () => dispatch(logIn()),
  logOut: () => dispatch(logOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
