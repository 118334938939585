import React, { Component } from 'react';

import { Panel, PanelBody, PanelTitle, UserProfile } from 'apriori-react-core';
import { MY_PROFILE, UNABLE_TO_LOAD_USER_PROFILE } from '../../constants/messages';

import authenticationService from '../../services/AuthenticationService';

class UserManagement extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profile: null
    };
  }

  async componentDidMount() {
    const profile = await authenticationService.getUserProfile();
    this.setState({ profile });
  }

  isLoading() {
    const { profile } = this.state;
    return !profile;
  }

  render() {
    const { profile } = this.state;

    return (
      <div className='col-4 full-height'>
        <Panel>
          <PanelTitle>
            {MY_PROFILE}
          </PanelTitle>
          <PanelBody isLoading={this.isLoading()} noContentMessage={UNABLE_TO_LOAD_USER_PROFILE}>
            <UserProfile userProfile={profile} />
          </PanelBody>
        </Panel>
      </div>
    );
  };
}

export default UserManagement;
