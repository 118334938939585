import {attributeNamesDictionary} from "../constants/part-attributes";

export const findAttributeByName = (attributes, name) => {
  return attributes.filter(attribute => attribute.name === name)
      .map(attribute => {
        return attribute.value;
      }).find(Boolean);
};

export const getAttributeLookupDictionary = (attributes) => {
    return new Map(attributes.map(attr => [attr.name, attr.value]));
}

export const getValueOfStateProperty = (stateProperty) => {
  return stateProperty || '';
}

export const transformStateObjAttributesBeforeSaving = (attributes, newPart, part) => {
    const attributesFromForm = Object.fromEntries(Object.entries(newPart)
        .reduce((acc, obj) => {
            if (obj[1] != null) {
                const attrId = getAttributeId(attributes, obj[0]);
                if (attrId) {
                    acc.push([attrId, obj[1]])
                }
            }
            return acc;
        }, [])
    );

    return mergeAttributes(part, attributesFromForm);
};

const getAttributeId = (attributes, attributeName) => {
    return attributes.filter(attribute => attribute.name === attributeNamesDictionary.get(attributeName))
        .map(attribute => attribute.id)
        .find(Boolean);
}

const mergeAttributes = (part, attributes) => {
    if (!part) {
        return attributes;
    }
    for (let idx in part.attributes) {
        const attrId = part.attributes[idx].id;
        if (!attributes[attrId]) {
            attributes[attrId] = part.attributes[idx].value;
        }
    }

    return attributes;
};
