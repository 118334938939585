import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardText, CardBody, CardSubtitle, CardTitle, UncontrolledTooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { READY_FOR_EXPORT } from '../../constants/messages';
import { attributeNamesDictionary } from "../../constants/part-attributes";

import { renderApIcon, renderDataSheetIcon } from '../../helpers/icon-helpers'
import { getAttributeLookupDictionary } from '../../helpers/part-helper'

import StatusIcon from '../widgets/StatusIcon';

class PartCard extends Component {

  cardClasses = () => this.isSelectedForCosting() ? 'part-card selected' : 'part-card';

  cardClicked = () => {
    const { part, partClicked } = this.props;
    partClicked(part.identity);
  };

  isSelectedForCosting = () => {
    const { part, selectedPartIdentities } = this.props;
    return selectedPartIdentities.includes(part.identity);
  };

  isReadyForExport = () => {
    const { part } = this.props;
    return part.status === READY_FOR_EXPORT;
  };

  renderAddedByIcon = () => {
    const { part } = this.props;

    if (part.isUserPart) {
      return (
        <div className='user-icon'>
          <FontAwesomeIcon id={`user-icon-${part.identity}`} icon={faUser} />
          <UncontrolledTooltip
            placement='top'
            target={`user-icon-${part.identity}`}
          >
            Added by user
          </UncontrolledTooltip>
        </div>
      );
    }

    return renderApIcon(part.identity);
  };

  renderProperty = (name, value, isRequired, valuePrefix = '') => {

    if (value) {
      return (
        <CardText className='property'>
          <span className='title'>{name}</span>
          <span className='value'>{valuePrefix}{value}</span>
        </CardText>
      );
    }

    if (isRequired) {
      return (
        <CardText className='property'>
          <span className='title'>{name}</span>
          <span className='value missing'>Missing</span>
        </CardText>
      );
    }
  };

  render() {
    const { part, type } = this.props;
    const attrLookup = getAttributeLookupDictionary(part.attributes);

    return (
      <Card tag='span' className={this.cardClasses()} id={part.identity} onClick={this.cardClicked}>
        <CardBody>
          <CardTitle>
            <div className='title-left'>
              <StatusIcon status={part.status} uniqueId={part.identity} />
              <span>{attrLookup.get(attributeNamesDictionary.get('manufacturerPartNumber'))}</span>
            </div>
            <div className='title-right'>
              <small className='text-muted mr-3'>{attrLookup.get( attributeNamesDictionary.get('classification'))}</small>
              {this.renderAddedByIcon()}
            </div>
          </CardTitle>
          <div className='divider' />
          <CardSubtitle>
            {attrLookup.get(attributeNamesDictionary.get('description'))}
          </CardSubtitle>
          <div className='properties'>
            {renderDataSheetIcon(attrLookup.get(attributeNamesDictionary.get('dataSheetUrl')))}
            {this.renderProperty('Average Cost', attrLookup.get(attributeNamesDictionary.get('averageCost')), true, '$')}
            {this.renderProperty('Minimum Cost', attrLookup.get(attributeNamesDictionary.get('minimumCost')), false, '$')}
            {type === 'PCBA' && this.renderProperty('Mount Type', attrLookup.get(attributeNamesDictionary.get('mountType')), true)}
            {type === 'PCBA' && this.renderProperty('Pin Count', attrLookup.get(attributeNamesDictionary.get('pinCount')), true)}
            {this.renderProperty('Manufacturer', attrLookup.get(attributeNamesDictionary.get('manufacturer')), false)}
            {this.renderProperty('Availability', attrLookup.get(attributeNamesDictionary.get('availability')), false)}
            {this.renderProperty('RoHS', attrLookup.get(attributeNamesDictionary.get('rohs')), false)}
            {this.renderProperty('RoHS Version', attrLookup.get(attributeNamesDictionary.get('rohsVersion')), false)}
          </div>
        </CardBody>
      </Card>
    );
  }
}

PartCard.defaultProps = {
  selectedPartIdentities: []
};

PartCard.propTypes = {
  part: PropTypes.instanceOf(Object).isRequired,
  partClicked: PropTypes.func.isRequired,
  selectedPartIdentities: PropTypes.instanceOf(Array),
  type: PropTypes.string.isRequired
};

export default PartCard;
