import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFolderPlus, 
  faPlus, 
  faPowerOff, 
  faTrash 
} from '@fortawesome/free-solid-svg-icons';

import { 
  Panel, 
  PanelBody, 
  PanelFooter, 
  PanelTitle 
} from 'apriori-react-core';

import { ROUTE_HOME } from '../../constants/routes';

import {
  ACCOUNTS,
  ADD_NEW_ACCOUNT,
  ARE_YOU_SURE,
  ARE_YOU_SURE_YOU_WANT_TO_DELETE,
  NO_ACCOUNTS_FOUND,
  UPLOAD_BOM,
  YOU_ARE_ABOUT_TO_ACTIVATE_ACCOUNT
} from '../../constants/messages';

import modal from '../../services/ModalService';

import AccountCard from './AccountCard';

class AccountList extends Component {

  activate = () => {
    modal.openConfirmModal(
      ARE_YOU_SURE,
      YOU_ARE_ABOUT_TO_ACTIVATE_ACCOUNT(this.getSelectedAccountName()),
      () => {
        this.props.activateAccount(this.props.selectedAccount);
        modal.closeModal();
      }
    );
  };

  canActivateAccount = () => this.getSelectedAccount() && this.getSelectedAccount().isValid;

  canDeleteAccount = () => this.getSelectedAccount() && !this.getSelectedAccount().isActive;

  delete = () => {
    modal.openDeleteModal(
      ARE_YOU_SURE,
      ARE_YOU_SURE_YOU_WANT_TO_DELETE(this.getSelectedAccountName()),
      () => {
        this.props.deleteAccount(this.props.selectedAccount);
        modal.closeModal();
      }
    );
  };

  enterAddMode = () => this.props.selectAccount('');

  getSelectedAccount = () => this.props.accounts.find(account => account.identity === this.props.selectedAccount);

  getSelectedAccountName = () => this.getSelectedAccount() ? this.getSelectedAccount().name : null;

  hasContent = () => {
    const { accounts } = this.props;
    return !!accounts && accounts.length > 0;
  };

  renderActivateButton() {
    return [
      <button
        key='account-list-activate-button'
        type='button'
        id='activate-button'
        className='btn btn-outline-primary icon-button mr-1'
        onClick={this.activate}
        disabled={!this.canActivateAccount()}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </button>,
      <UncontrolledTooltip
        key='account-list-activate-button-tooltip'
        placement='top'
        target='activate-button'
      >
        Activate {this.getSelectedAccount() ? this.getSelectedAccount().name : ''}
      </UncontrolledTooltip >
    ];
  }

  renderAddButton() {
    return [
      <button
        key='account-list-add-button'
        type='button'
        id='add-button'
        className='btn btn-outline-primary icon-button mr-1'
        onClick={this.enterAddMode}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>,
      <UncontrolledTooltip
        key='account-list-add-button-tooltip'
        placement='top'
        target='add-button'
      >
        {ADD_NEW_ACCOUNT}
      </UncontrolledTooltip >
    ];
  }

  renderDeleteButton() {
    return [
      <button
        key='account-list-delete-button'
        type='button'
        id='delete-button'
        className='btn btn-outline-primary icon-button mr-1'
        onClick={this.delete}
        disabled={!this.canDeleteAccount()}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>,
      <UncontrolledTooltip
        key='account-list-delete-button-tooltip'
        placement='top'
        target='delete-button'
      >
        Delete {this.getSelectedAccount() ? this.getSelectedAccount().name : ''}
      </UncontrolledTooltip >
    ];
  }

  renderCards() {
    return this.props.accounts.map((account) => (
      <AccountCard
        key={account.identity}
        account={account}
        selectAccount={this.props.selectAccount}
        selectedAccount={this.props.selectedAccount}
      />
    ));
  };

  render() {
    return (
      <Panel className='account-list'>
        <PanelTitle>
          <div className='title-left'>
            {ACCOUNTS}
          </div>
          <div className='title-right'>
            {this.renderDeleteButton()}
            {this.renderActivateButton()}
            {this.renderAddButton()}
          </div>
        </PanelTitle>
        <PanelBody hasContent={this.hasContent()} noContentMessage={NO_ACCOUNTS_FOUND}>
          {this.renderCards()}
        </PanelBody>
        <PanelFooter>
          <Link to={ROUTE_HOME}>
            <Button
              outline
              color='primary'
            >
              <FontAwesomeIcon icon={faFolderPlus} className='mr-2' />
              {UPLOAD_BOM}
            </Button>
          </Link>
        </PanelFooter>
      </Panel>
    );
  }
}

AccountList.defaultProps = {
  selectedAccount: null
};

AccountList.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  activateAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  selectedAccount: PropTypes.string,
  selectAccount: PropTypes.func.isRequired
};

export default AccountList;
