import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

import { SplitButton, Toast } from 'apriori-react-core';

import {
  DROPZONE_SUBTITLE,
  DROPZONE_TITLE,
  UPLOAD_PCBA_SUB_TEXT,
  UPLOAD_PCBA_TEXT,
  UPLOAD_WH_SUB_TEXT,
  UPLOAD_WH_TEXT
} from '../../constants/messages';

class BillOfMaterialsUpload extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      fileToUpload: undefined
    };
  }

  splitButtonOptions = [
    {
      onClick: () => this.uploadFile('PCBA'),
      text: UPLOAD_PCBA_TEXT,
      subText: UPLOAD_PCBA_SUB_TEXT,
      value: 'PCBA'
    },
    {
      onClick: () => this.uploadFile('WH'),
      text: UPLOAD_WH_TEXT,
      subText: UPLOAD_WH_SUB_TEXT,
      value: 'WH'
    }
  ];

  onDrop = (files) => {
    if (files === undefined || files.length === 0) {
      Toast.error(`Error: ${DROPZONE_SUBTITLE}`);
    }
    else {
      this.setState({ fileToUpload: files[0] });
    }
  };

  uploadFile = (type) => {
    const { uploadBillOfMaterials } = this.props;
    const { fileToUpload } = this.state;

    uploadBillOfMaterials(fileToUpload, type);
  };

  renderMessage() {
    const { fileToUpload } = this.state;

    if (fileToUpload) {
      return ([
        <h1 key='bill-of-materials-dropzone-file-icon'>
          <FontAwesomeIcon icon={faFileCsv} />
        </h1>,
        <h4 key='bill-of-materials-dropzone-file-name' className='file-name'>
          {fileToUpload.name}
        </h4>
      ]);
    }

    return ([
      <h4 id="qa-drag-drop" key='bill-of-materials-dropzone-heading'>
        {DROPZONE_TITLE}
      </h4>,
      <em id="qa-valid-extension" key='bill-of-materials-dropzone-message'>
        {`(${DROPZONE_SUBTITLE})`}
      </em>
    ]);
  }

  render() {
    const { fileToUpload } = this.state;

    return (
      <div className='bill-of-materials-upload'>
        <Dropzone accept='.csv' onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {this.renderMessage()}
            </div>
          )}
        </Dropzone>
        <SplitButton defaultOption='PCBA' disabled={!fileToUpload} options={this.splitButtonOptions} />
      </div>
    );
  }
}

BillOfMaterialsUpload.propTypes = {
  uploadBillOfMaterials: PropTypes.func.isRequired
};

export default BillOfMaterialsUpload;
