import { connect } from 'react-redux';

import BillOfMaterialsList from "../components/bill-of-materials/BillOfMaterialsList";

import {
  deleteBillOfMaterials,
  exportBillOfMaterials,
  listBillOfMaterials
} from "../actions/bill-of-materials-actions";

const mapStateToProps = (state) => ({
  billOfMaterialsList: state.billOfMaterials.billOfMaterialsList,
  listBillOfMaterialsPending: state.billOfMaterials.listBillOfMaterialsRequestStatus.pending,
  totalNumberOfBillOfMaterials: state.billOfMaterials.totalItemCount
});

const mapDispatchToProps = (dispatch) => ({
  deleteBillOfMaterials: (identity) => dispatch(deleteBillOfMaterials(identity)),
  exportBillOfMaterials: (identity) => dispatch(exportBillOfMaterials(identity)),
  listBillOfMaterials: (pageNumber, pageSize) => dispatch(listBillOfMaterials(pageNumber, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillOfMaterialsList);
