import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_PENDING,
  AUTHENTICATION_SUCCEEDED,
  USER_LOGGED_OUT,
  SESSION_CHECK_INITIATED
} from '../constants/actions';

const initialState = {
  authenticationRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  sessionLastCheckedAt: null
};

export default function reducer(state = initialState, action) {
  switch(action.type) {

    case AUTHENTICATION_FAILED: {
      const authenticationRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        authenticationRequestStatus
      };
    }

    case AUTHENTICATION_PENDING: {
      const authenticationRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        authenticationRequestStatus
      };
    }

    case AUTHENTICATION_SUCCEEDED: {
      const authenticationRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        authenticationRequestStatus
      };
    }

    case SESSION_CHECK_INITIATED: {
      return {
        ...state,
        sessionLastCheckedAt: Date.now()
      };
    }

    case USER_LOGGED_OUT: {
      return state;
    }

    default: {
      return state;
    }
  }
};
