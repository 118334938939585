import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom'

import { Button, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faChevronLeft,
  faFolderPlus,
  faGavel,
  faInfoCircle,
  faQuestionCircle as faQuestionCircleSolid,
  faSignInAlt,
  faSignOutAlt,
  faTasks,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import { Logo, Messages } from 'apriori-react-core';

import { APP_NAME, MANAGE_ACCOUNTS, UPLOAD_BOM } from '../../constants/messages';
import {
  ROUTE_ACCOUNTS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_TERMS_OF_USE,
  ROUTE_USER_PROFILE
} from '../../constants/routes';

import ActiveAccount from '../../containers/ActiveAccount';

class Header extends Component {

  static renderBrand() {
    return (
      <div className='brand'>
        <Logo classes='logo' mode='light' />
        <span className='vertical-bar' />
        <span className='app-name'>{APP_NAME}</span>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      profile: null,
      sessionLastCheckedAt: null
    };
  }

  /**
   * When the component is mounted
   * check if the user is authenticated.
   */
  componentDidMount() {
    return this.checkAuthentication();
  }

  /**
   * If `sessionLastCheckedAt` has been set,
   * this informs subscribers to initiate an authentication check
   * and subsequently update the component
   * causing another `componentDidUpdate` round trip.
   *
   * If the user profile exists in local state and it has changed,
   * proceed to load the current user's profile.
   */
  componentDidUpdate(prevProps, prevState) {
    const shouldCheckAuthentication =
        prevProps.sessionLastCheckedAt &&
        prevProps.sessionLastCheckedAt !== prevState.sessionLastCheckedAt;

    if (shouldCheckAuthentication) {
      return this.checkAuthentication(prevProps.sessionLastCheckedAt);
    }

    const { isAuthenticated, profile } = this.state;

    const shouldLoadUserProfile =
        isAuthenticated &&
        (!prevState.profile || prevState.profile !== profile);

    if (shouldLoadUserProfile) {
      return this.loadUserProfile();
    }
  }

  checkAuthentication = async (sessionLastCheckedAt) => {
    const isAuthenticated = await this.props.isAuthenticated();
    this.setState({ isAuthenticated, sessionLastCheckedAt });
  };

  loadUserProfile = async () => {
    const { getUserProfile } = this.props;

    const profile = await getUserProfile();
    this.setState({ profile });
  };

  getName = () => {
    const { profile } = this.state;

    return this.hasFirstName()
        ? profile.firstName
        : profile.username;
  };

  hasFirstName = () => {
    const { profile } = this.state;
    return !!profile && !!profile.firstName;
  };

  renderBackButton() {
    const { location } = this.props;

    if (location.pathname === ROUTE_HOME) {
      return;
    }

    return (
      <Link className='back-button' to={ROUTE_HOME}>
        <Button>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </Link>
    );
  }

  renderHelpDropdown() {
    const { isAuthenticated } = this.state;

    if (!isAuthenticated) {
      return '';
    }

    return (
      <UncontrolledDropdown className='help-dropdown mr-2'>
        <DropdownToggle className='transparent'>
          <FontAwesomeIcon icon={faQuestionCircle} className='mr-2' />
          <FontAwesomeIcon icon={faAngleDown} />
        </DropdownToggle>
        <DropdownMenu right>
          <a target='_blank' rel='noopener noreferrer' href='https://support.apriori.com'>
            <DropdownItem>
              <FontAwesomeIcon icon={faQuestionCircleSolid} />
              Help
            </DropdownItem>
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.apriori.com/about-us'>
            <DropdownItem>
              <FontAwesomeIcon icon={faInfoCircle} />
              About
            </DropdownItem>
          </a>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderUserDropdown() {
    const { logOut } = this.props;
    const { isAuthenticated, profile } = this.state;

    if (!isAuthenticated || !profile) {
      return '';
    }

    return (
      <UncontrolledDropdown className='user-dropdown'>
        <DropdownToggle className='transparent'>
          <span className='mr-2 user-dropdown'> Hi {this.getName()}</span>
          <FontAwesomeIcon icon={faUserCircle} className='mr-2' />
          <FontAwesomeIcon icon={faAngleDown} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className='active-account no-hover'>
            <ActiveAccount />
          </DropdownItem>
          <DropdownItem divider />
          <Link to={ROUTE_HOME}>
            <DropdownItem>
              <FontAwesomeIcon icon={faFolderPlus} />
              {UPLOAD_BOM}
            </DropdownItem>
          </Link>
          <DropdownItem divider />
          <Link to={ROUTE_USER_PROFILE}>
            <DropdownItem>
              <FontAwesomeIcon icon={faUser} />
              {Messages.MY_PROFILE}
            </DropdownItem>
          </Link>
          <Link to={ROUTE_ACCOUNTS}>
            <DropdownItem>
              <FontAwesomeIcon icon={faTasks} />
              {MANAGE_ACCOUNTS}
            </DropdownItem>
          </Link>
          <Link to={ROUTE_TERMS_OF_USE}>
            <DropdownItem>
              <FontAwesomeIcon icon={faGavel} />
              {Messages.TERMS_OF_USE}
            </DropdownItem>
          </Link>
          <DropdownItem divider />
          <DropdownItem onClick={logOut}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            {Messages.LOG_OUT}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderSignInButton() {
    const { isAuthenticated } = this.state;

    if (isAuthenticated) {
      return '';
    }

    return (
      <div className='navbar-nav ml-auto nav-right'>
        <Link to={ROUTE_LOGIN}>
          <Button outline color='white'>
            <FontAwesomeIcon icon={faSignInAlt} className='mr-2' />
            {Messages.LOG_IN}
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    return (
      <header>
        <nav className='navbar navbar-expand-lg navbar-dark bg-primary text-light'>
          {this.renderBackButton()}
          {Header.renderBrand()}
          <div className='navbar-nav ml-auto nav-right'>
            {this.renderHelpDropdown()}
            {this.renderUserDropdown()}
            {this.renderSignInButton()}
          </div>
        </nav>
      </header>
    );
  }
}

Header.defaultProps = {
  sessionLastCheckedAt: null
};

Header.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  logOut: PropTypes.func.isRequired,
  sessionLastCheckedAt: PropTypes.number
};

export default Header;
