import { HttpRequestHelpers } from 'apriori-react-core';

import {
  ADD_PART_FAILED,
  ADD_PART_PENDING,
  ADD_PART_SUCCEEDED,
  COST_PARTS_FAILED,
  COST_PARTS_PENDING,
  COST_PARTS_SUCCEEDED,
  PART_SELECTED,
  PART_SELECTION_CLEARED,
  SELECT_PART_FOR_EXPORT_FAILED,
  SELECT_PART_FOR_EXPORT_PENDING,
  SELECT_PART_FOR_EXPORT_SUCCEEDED,
  UPDATE_PART_FAILED,
  UPDATE_PART_PENDING,
  UPDATE_PART_SUCCEEDED,
  GET_ATTRIBUTE_DICTIONARY_FAILED,
  GET_ATTRIBUTE_DICTIONARY_PENDING,
  GET_ATTRIBUTE_DICTIONARY_SUCCEEDED,
} from '../constants/actions';

import { getBillOfMaterials } from "./bill-of-materials-actions";
import { listLineItems } from "./line-item-actions";

import {PART_URL, PART_URL_UPDATE, PARTS_URL, ATTRIBUTES_URL} from '../constants/endpoints';

export const addCustomPart = (billOfMaterialsIdentity, lineItemIdentity, part) => async (dispatch) => {

  dispatch(addPartPending());

  const body = JSON.stringify(part);

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = PARTS_URL(billOfMaterialsIdentity, lineItemIdentity);

  return HttpRequestHelpers.post(url, body, headers)
    .then(response => {
      dispatch(addPartSucceeded(part));
      dispatch(clearPartSelection());
      dispatch(listLineItems(billOfMaterialsIdentity));
      dispatch(getBillOfMaterials(billOfMaterialsIdentity));
      return response;
    })
    .catch(error => {
      dispatch(addPartFailed());
      console.log(error);
    });
};

export const clearPartSelection = () => (dispatch) => {
  dispatch(partSelectionCleared());
};

export const costParts = (billOfMaterialsIdentity, lineItemIdentity, partIdentities) => async (dispatch) => {

  dispatch(costPartsPending());

  const body = JSON.stringify(partIdentities);
  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${PARTS_URL(billOfMaterialsIdentity, lineItemIdentity)}/cost`;

  return HttpRequestHelpers.post(url, body, headers)
    .then(response => {
      dispatch(costPartsSucceeded());
      dispatch(listLineItems(billOfMaterialsIdentity));
      dispatch(getBillOfMaterials(billOfMaterialsIdentity));
      return response;
    })
    .catch(error => {
      dispatch(costPartsFailed());
      console.log(error);
    });
};

export const updatePart = (billOfMaterialsIdentity, lineItemIdentity, partIdentity, part) => async (dispatch) => {

  dispatch(updatePartPending());

  const body = JSON.stringify(part);
  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = PART_URL_UPDATE(billOfMaterialsIdentity, lineItemIdentity, partIdentity);

  return HttpRequestHelpers.patch(url, body, headers)
    .then(response => {
      dispatch(updatePartSucceeded());
      dispatch(listLineItems(billOfMaterialsIdentity));
      dispatch(getBillOfMaterials(billOfMaterialsIdentity));
      return response;
    })
    .catch(error => {
      dispatch(updatePartFailed());
      console.log(error);
    });
};

export const selectPart = (identity) => (dispatch) => {
  dispatch(partSelected(identity));
};

export const selectPartForExport = (billOfMaterialsIdentity, lineItemIdentity, partIdentity) => async (dispatch) => {

  dispatch(selectPartForExportPending());

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${PART_URL(billOfMaterialsIdentity, lineItemIdentity, partIdentity)}/select`;


  return HttpRequestHelpers.post(url, null, headers)
    .then(response => {
      dispatch(selectPartForExportSucceeded());
      dispatch(clearPartSelection());
      dispatch(getBillOfMaterials(billOfMaterialsIdentity));
      return response;
    })
    .catch(error => {
      dispatch(selectPartForExportFailed());
      console.log(error);
    });
};

export const getAttributeDictionary = (type) => async (dispatch) => {

    dispatch(getAttributeDictionaryPending());

    const headers = HttpRequestHelpers.getDefaultHeaders();
    const url = ATTRIBUTES_URL(type);

    HttpRequestHelpers.get(url, headers)
        .then(response => {
            dispatch(getAttributeSucceeded(response));
            return response;
        })
        .catch(error => {
            dispatch(getAttributeDictionaryFailed());
            console.log(error);
        })
};

const addPartFailed = () => ({
  type: ADD_PART_FAILED,
  payload: null
});

const addPartPending = () => ({
  type: ADD_PART_PENDING,
  payload: null
});

const addPartSucceeded = part => ({
  type: ADD_PART_SUCCEEDED,
  payload: part
});

const costPartsFailed = () => ({
  type: COST_PARTS_FAILED,
  payload: null
});

const costPartsPending = () => ({
  type: COST_PARTS_PENDING,
  payload: null
});

const costPartsSucceeded = () => ({
  type: COST_PARTS_SUCCEEDED,
  payload: null
});

const partSelected = identity => ({
  type: PART_SELECTED,
  payload: identity
});

const partSelectionCleared = () => ({
  type: PART_SELECTION_CLEARED,
  payload: null
});

const updatePartFailed = () => ({
  type: UPDATE_PART_FAILED,
  payload: null
});

const updatePartPending = () => ({
  type: UPDATE_PART_PENDING,
  payload: null
});

const updatePartSucceeded = () => ({
  type: UPDATE_PART_SUCCEEDED,
  payload: null
});

const selectPartForExportFailed = () => ({
  type: SELECT_PART_FOR_EXPORT_FAILED,
  payload: null
});

const selectPartForExportPending = () => ({
  type: SELECT_PART_FOR_EXPORT_PENDING,
  payload: null
});

const selectPartForExportSucceeded = () => ({
  type: SELECT_PART_FOR_EXPORT_SUCCEEDED,
  payload: null
});

const getAttributeDictionaryPending = () => ({
    type: GET_ATTRIBUTE_DICTIONARY_PENDING,
    payload: null
});

const getAttributeDictionaryFailed = () => ({
    type: GET_ATTRIBUTE_DICTIONARY_FAILED,
    payload: null
});

const getAttributeSucceeded = attributesDictionary => ({
    type: GET_ATTRIBUTE_DICTIONARY_SUCCEEDED,
    payload: attributesDictionary
});
