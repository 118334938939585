import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getActiveAccount } from '../actions/account-actions';
import Home from "../components/workspace/Home";

const mapStateToProps = (state) => ({
  activeAccountPending: state.account.getActiveAccountRequestStatus.pending,
  billOfMaterialsList: state.billOfMaterials.billOfMaterialsList,
  deleteBillOfMaterialsPending: state.billOfMaterials.deleteBillOfMaterialsRequestStatus.pending,
  exportBillOfMaterialsPending: state.billOfMaterials.exportBillOfMaterialsRequestStatus.pending,
  uploadBillOfMaterialsPending: state.billOfMaterials.uploadBillOfMaterialsRequestStatus.pending
});

const mapDispatchToProps = (dispatch) => ({
  getActiveAccount: () => dispatch(getActiveAccount()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
