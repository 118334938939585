import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';

import logo from '../assets/ap-logo-black.png';

export const renderApIcon = (identity) => [
  <i key='ap-icon' id={`ap-icon-${identity}`} className='ap-icon'>
    <img src={logo} alt='aP Logo' />
  </i>,
  <UncontrolledTooltip
    key='ap-icon-tooltip'
    placement='top'
    target={`ap-icon-${identity}`}
  >
    Provided by aPriori
  </UncontrolledTooltip>
];

export const renderDataSheetIcon = (url) => {
  if (url) {

    let icon = faFile;
    const ext = fileExtension(url).toLowerCase();

    if (ext === '.pdf') {
      icon = faFilePdf;
    }
    else if (ext === '.xls' || ext === '.xlsx') {
      icon = faFileExcel;
    }

    return (
      <a className='btn-link datasheet-icon property' href={url} target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon icon={icon} />
      </a>
    );
  }

  return null;
};

const fileExtension = (url) => {
  const lastPathItem = url.substr(1 + url.lastIndexOf('/')).split('?')[0];
  return lastPathItem.split('#')[0].substr(lastPathItem.lastIndexOf('.'));
};
