import { connect } from 'react-redux';

import { getActiveAccount } from '../actions/account-actions';
import ActiveAccount from '../components/header/ActiveAccount';

const mapStateToProps = (state) => ({
  account: state.account.activeAccount,
  failed: state.account.getActiveAccountRequestStatus.failed,
  pending: state.account.getActiveAccountRequestStatus.pending
});

const mapDispatchToProps = (dispatch) => ({
  getAccountStatus: () => dispatch(getActiveAccount())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAccount);
