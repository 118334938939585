import React, { Component } from 'react';

import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import { faBan, faBug, faHome, faLock, faRedo, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { ErrorDisplay, Footer, Messages, TermsOfUse } from 'apriori-react-core';

import {
  APP_NAME,
  COPYRIGHT_START_YEAR,
  RETRY,
  SOMETHING_WENT_WRONG_MESSAGE,
  SOMETHING_WENT_WRONG_TITLE
} from '../constants/messages';

import {
  ROUTE_ACCOUNTS,
  ROUTE_BILL_OF_MATERIALS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_LOGIN_CALLBACK,
  ROUTE_LOGOUT,
  ROUTE_NOT_AUTHENTICATED,
  ROUTE_SOMETHING_WENT_WRONG,
  ROUTE_TERMS_OF_USE,
  ROUTE_USER_PROFILE
} from '../constants/routes';

import history from '../history';

import Logout from './authentication/Logout';
import PrivateRoute from './authentication/PrivateRoute';
import UserManagement from './workspace/UserManagement';

import AuthenticationCallback from '../containers/AuthenticationCallback';
import BillOfMaterialsWorkbench from '../containers/BillOfMaterialsWorkbench';
import Header from '../containers/Header';
import Home from '../containers/Home';
import Login from '../containers/Login';
import AccountWorkbench from '../containers/AccountWorkbench';
import authenticationService from '../services/AuthenticationService';

const packageJson = require('../../package.json');

/**
 * The main view for this single-page web application.
 */
class App extends Component {

  render() {
    return ([
      <Helmet key='helmet'>
        <title>{`aPriori ${APP_NAME}`}</title>
      </Helmet>,
      <Header key='header' />,
      <main key='main'>
        <Switch>
          <Route exact path={ROUTE_LOGIN} component={Login} />
          <Route exact path={ROUTE_LOGIN_CALLBACK} component={AuthenticationCallback} />
          <Route exact path={ROUTE_LOGOUT} component={Logout} />
          <Route
            exact
            path={ROUTE_TERMS_OF_USE}
            component={() => (
              <TermsOfUse
                releaseDate={packageJson.releaseDate}
                title={APP_NAME}
                version={packageJson.version}
              />
            )}
          />

          <Route
            exact
            path={ROUTE_NOT_AUTHENTICATED}
            component={() => (
              <ErrorDisplay
                buttons={[
                  {
                    icon: faRedo,
                    onClick: () => history.push(ROUTE_LOGIN),
                    text: RETRY
                  },
                  {
                    icon: faSignOutAlt,
                    onClick: authenticationService.signOut,
                    text: Messages.LOG_OUT
                  }
                ]}
                icon={faLock}
                message={Messages.NOT_AUTHENTICATED_MESSAGE}
                title={Messages.NOT_AUTHENTICATED_TITLE}
              />
            )}
          />
          <Route
            exact
            path={ROUTE_SOMETHING_WENT_WRONG}
            component={() => (
              <ErrorDisplay
                buttons={[
                  {
                    icon: faHome,
                    onClick: () => history.push(ROUTE_HOME),
                    text: Messages.GO_TO_HOMEPAGE
                  }
                ]}
                icon={faBug}
                message={SOMETHING_WENT_WRONG_MESSAGE}
                title={SOMETHING_WENT_WRONG_TITLE}
              />
            )}
          />

          <PrivateRoute
            exact
            path={ROUTE_HOME}
            component={Home}
          />
          <PrivateRoute
            exact
            path={ROUTE_ACCOUNTS}
            component={props => <AccountWorkbench {...props} />}
          />
          <PrivateRoute
            exact
            path={`${ROUTE_BILL_OF_MATERIALS}/:id`}
            component={props => <BillOfMaterialsWorkbench {...props} />}
          />
          <PrivateRoute
            exact
            path={ROUTE_USER_PROFILE}
            component={() => <UserManagement />}
          />

          <Route
            component={() => (
              <ErrorDisplay
                buttons={[
                  {
                    icon: faHome,
                    onClick: () => history.push(ROUTE_HOME),
                    text: Messages.GO_TO_HOMEPAGE
                  }
                ]}
                icon={faBan}
                message={Messages.NOT_FOUND_MESSAGE}
                title={Messages.NOT_FOUND_TITLE}
              />
            )}
          />
        </Switch>
      </main>,
      <Footer key='footer' releaseDate={packageJson.releaseDate} version={packageJson.version}
        copyrightStartYear={COPYRIGHT_START_YEAR} />
    ]);
  };
}

export default App;
