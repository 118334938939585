import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { ROUTE_LOGIN } from '../../constants/routes';

class Logout extends Component {

  render() {
    const { location } = this.props;
    return <Redirect to={{ pathname: ROUTE_LOGIN, state: { from: location } }} />;
  }
}

Logout.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

export default Logout;
