import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { Actions, Messages } from 'apriori-react-core';

import { eventEmitter } from '../index';

const closeModal = () => eventEmitter.emit(Actions.CONFIRMATION_MODAL_CLOSED);

const openModal = options => eventEmitter.emit(Actions.CONFIRMATION_MODAL_OPENED, options);

const openConfirmModal = (title, message, onConfirm) => {
  openModal({
    confirmButtonText: Messages.CONFIRM,
    color: 'primary',
    icon: faCheckCircle,
    message,
    onConfirm,
    title
  });
};

const openDeleteModal = (title, message, onConfirm) => {
  openModal({
    confirmButtonText: Messages.DELETE,
    color: 'danger',
    icon: faTimesCircle,
    message,
    onConfirm,
    title
  });
};

export default {
  closeModal,
  openModal,
  openConfirmModal,
  openDeleteModal
};
