import { connect } from 'react-redux';

import { activateAccount, deleteAccount, selectAccount } from "../actions/account-actions";

import AccountList from "../components/account/AccountList";

const mapStateToProps = (state) => ({
  accounts: state.account.accounts,
  selectedAccount: state.account.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
  activateAccount: (identity) => dispatch(activateAccount(identity)),
  deleteAccount: (identity) => dispatch(deleteAccount(identity)),
  selectAccount: (identity) => dispatch(selectAccount(identity))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
