import { connect } from 'react-redux';

import BillOfMaterialsWorkbench from "../components/workspace/BillOfMaterialsWorkbench";

import { getBillOfMaterials } from "../actions/bill-of-materials-actions";

const mapStateToProps = (state) => ({
  billOfMaterials: state.billOfMaterials.billOfMaterials,
  costPartsPending: state.parts.costPartsRequestStatus.pending,
  exportBillOfMaterialsPending: state.billOfMaterials.exportBillOfMaterialsRequestStatus.pending,
  getBillOfMaterialsPending: state.billOfMaterials.getBillOfMaterialsRequestStatus.pending,
  listLineItemsPending: state.lineItem.listLineItemsRequestStatus.pending,
  lineItems: state.lineItem.lineItems,
  selectedLineItem: state.lineItem.selectedLineItem
});

const mapDispatchToProps = (dispatch) => ({
  getBillOfMaterials: (identity, shouldResetLineItemPageNumber = false) =>
    dispatch(getBillOfMaterials(identity, shouldResetLineItemPageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillOfMaterialsWorkbench);
