export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGIN_CALLBACK = '/callback';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_NOT_AUTHENTICATED = '/not-authenticated';
export const ROUTE_SOMETHING_WENT_WRONG = '/something-went-wrong';
export const ROUTE_ACCOUNTS = '/accounts';
export const ROUTE_BILL_OF_MATERIALS = '/bill-of-materials';
export const ROUTE_USER_PROFILE = '/user-profile';
export const ROUTE_TERMS_OF_USE = '/terms-of-use';
