import {
  DELETE_BILL_OF_MATERIALS_FAILED,
  DELETE_BILL_OF_MATERIALS_PENDING,
  DELETE_BILL_OF_MATERIALS_SUCCEEDED,
  EXPORT_BILL_OF_MATERIALS_FAILED,
  EXPORT_BILL_OF_MATERIALS_PENDING,
  EXPORT_BILL_OF_MATERIALS_SUCCEEDED,
  GET_BILL_OF_MATERIALS_FAILED,
  GET_BILL_OF_MATERIALS_PENDING,
  GET_BILL_OF_MATERIALS_SUCCEEDED,
  LIST_BILL_OF_MATERIALS_FAILED,
  LIST_BILL_OF_MATERIALS_PENDING,
  LIST_BILL_OF_MATERIALS_SUCCEEDED,
  UPLOAD_BILL_OF_MATERIALS_FAILED,
  UPLOAD_BILL_OF_MATERIALS_PENDING,
  UPLOAD_BILL_OF_MATERIALS_SUCCEEDED
} from '../constants/actions';

const initialState = {
  billOfMaterials: {},
  billOfMaterialsList: [],
  deleteBillOfMaterialsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  exportBillOfMaterialsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  getBillOfMaterialsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  listBillOfMaterialsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  uploadBillOfMaterialsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  totalItemCount: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case DELETE_BILL_OF_MATERIALS_FAILED: {
      const deleteBillOfMaterialsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        deleteBillOfMaterialsRequestStatus
      };
    }

    case DELETE_BILL_OF_MATERIALS_PENDING: {
      const deleteBillOfMaterialsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        deleteBillOfMaterialsRequestStatus
      };
    }

    case DELETE_BILL_OF_MATERIALS_SUCCEEDED: {
      const deleteBillOfMaterialsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        deleteBillOfMaterialsRequestStatus
      };
    }

    case EXPORT_BILL_OF_MATERIALS_FAILED: {
      const exportBillOfMaterialsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        exportBillOfMaterialsRequestStatus
      };
    }

    case EXPORT_BILL_OF_MATERIALS_PENDING: {
      const exportBillOfMaterialsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        exportBillOfMaterialsRequestStatus
      };
    }

    case EXPORT_BILL_OF_MATERIALS_SUCCEEDED: {
      const exportBillOfMaterialsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        exportBillOfMaterialsRequestStatus
      };
    }

    case GET_BILL_OF_MATERIALS_FAILED: {
      const getBillOfMaterialsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        getBillOfMaterialsRequestStatus
      };
    }

    case GET_BILL_OF_MATERIALS_PENDING: {
      const getBillOfMaterialsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        getBillOfMaterialsRequestStatus
      };
    }

    case GET_BILL_OF_MATERIALS_SUCCEEDED: {
      const getBillOfMaterialsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        billOfMaterials: Object.assign({}, action.payload),
        getBillOfMaterialsRequestStatus
      };
    }

    case LIST_BILL_OF_MATERIALS_FAILED: {
      const listBillOfMaterialsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        listBillOfMaterialsRequestStatus
      };
    }

    case LIST_BILL_OF_MATERIALS_PENDING: {
      const listBillOfMaterialsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        listBillOfMaterialsRequestStatus
      };
    }

    case LIST_BILL_OF_MATERIALS_SUCCEEDED: {
      const listBillOfMaterialsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        billOfMaterialsList: action.payload.items,
        listBillOfMaterialsRequestStatus,
        totalItemCount: action.payload.totalItemCount
      };
    }

    case UPLOAD_BILL_OF_MATERIALS_FAILED: {
      const uploadBillOfMaterialsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        uploadBillOfMaterialsRequestStatus
      };
    }

    case UPLOAD_BILL_OF_MATERIALS_PENDING: {
      const uploadBillOfMaterialsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        uploadBillOfMaterialsRequestStatus
      };
    }

    case UPLOAD_BILL_OF_MATERIALS_SUCCEEDED: {
      const uploadBillOfMaterialsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        billOfMaterials: { ...action.payload },
        uploadBillOfMaterialsRequestStatus
      };
    }

    default: {
      return state;
    }

  }
}
