import { connect } from 'react-redux';

import { AuthenticationCallback } from 'apriori-react-core';

import { handleAuthentication } from '../actions/authentication-actions';
import { ROUTE_HOME, ROUTE_NOT_AUTHENTICATED } from '../constants/routes';

const mapStateToProps = () => ({
  authenticationFailedRoute: ROUTE_NOT_AUTHENTICATED,
  authenticationSucceededRoute: ROUTE_HOME
});

const mapDispatchToProps = (dispatch) => ({
  handleAuthentication: (callback) => dispatch(handleAuthentication(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCallback);
