import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle
} from 'reactstrap';

import { FormatHelpers } from 'apriori-react-core';

import { INVALID_ACCOUNT_CREDENTIALS } from '../../constants/messages';

class AccountCard extends Component {

  cardClasses = () => this.isSelected() ? 'account-card selected' : 'account-card';

  cardClicked = (event) => {
    const identity = event.currentTarget.id;
    this.props.selectAccount(identity);
  };

  isSelected = () => {
    const { account, selectedAccount } = this.props;
    return selectedAccount ? selectedAccount.includes(account.identity) : false;
  };

  renderSubtitle() {
    const { account } = this.props;

    if (account.isValid) {
      return (
        <small className='text-muted'>
          {account.type}, Expires {FormatHelpers.formatAsTimeFromNow(account.validTo)}
        </small>
      );
    }

    return (
      <small className='text-muted'>
        {account.type}, {INVALID_ACCOUNT_CREDENTIALS}
      </small>
    );
  }

  render() {
    const { account } = this.props;

    return (
      <Card tag='a' className={this.cardClasses()} id={account.identity} onClick={this.cardClicked}>
        <CardBody>
          <CardTitle>
            <div className='title-left'>
              {account.name}
            </div>
            <div className='title-right'>
              {account.isActive && <Badge color='info'>Active</Badge>}
            </div>
          </CardTitle>
          <CardSubtitle>
            {this.renderSubtitle()}
          </CardSubtitle>
        </CardBody>
      </Card>
    );
  }
}

AccountCard.defaultProps = {
  selectedAccount: null
};

AccountCard.propTypes = {
  account: PropTypes.instanceOf(Object).isRequired,
  selectedAccount: PropTypes.string,
  selectAccount: PropTypes.func.isRequired
};

export default AccountCard;
