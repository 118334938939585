import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { Loader } from 'apriori-react-core';

import { ROUTE_HOME } from '../../constants/routes';
import authenticationService from '../../services/AuthenticationService';

const queryString = require('query-string');

class Login extends Component {

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticationPending: true
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { token } = queryString.parse(location.search);

    if (token) {
      authenticationService.signIn(token);
    }

    const isAuthenticated = await authenticationService.isAuthenticated();
    this.setState({ isAuthenticated, isAuthenticationPending: false });
  }

  render() {
    const { location, logIn } = this.props;
    const { isAuthenticated, isAuthenticationPending } = this.state;

    if (isAuthenticationPending) {
      return <Loader message='Checking your session...' opaque />;
    }

    if (!isAuthenticated) {
      logIn();
      return <Loader message='Redirecting...' opaque />;
    }

    return <Redirect to={{ pathname: ROUTE_HOME, state: { from: location } }} />;
  }
}

Login.defaultProps = {
  location: null
};

Login.propTypes = {
  location: PropTypes.instanceOf(Object),
  logIn: PropTypes.func.isRequired
};

export default Login;
