import { connect } from 'react-redux';

import { uploadBillOfMaterials } from '../actions/bill-of-materials-actions';

import BillOfMaterialsUpload from '../components/bill-of-materials/BillOfMaterialsUpload';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  uploadBillOfMaterials: (inputFile, type) => dispatch(uploadBillOfMaterials(inputFile, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillOfMaterialsUpload);
