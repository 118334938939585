import auth0 from 'auth0-js';
import jwt from 'jsonwebtoken';

import {
  EnvironmentHelpers,
  HttpRequestHelpers,
  LocalStorageService,
  LoggingHelpers,
  User,
  Toast
} from 'apriori-react-core';

import { CURRENT_USER_URL } from '../constants/endpoints';
import { FAILED_TO_REFRESH_SESSION, UNABLE_TO_RETRIEVE_USER_PROFILE } from '../constants/messages';

import { checkSession } from '../actions/authentication-actions';
import { store } from '../index';

class AuthZeroAuthenticationService {

  /**
   * Auth0 Web Configuration for sign-in and session management.
   */
  authConfig;

  /**
   * Used to schedule a renewal of the token when it expires.
   */
  tokenRenewalTimeoutId;

  /**
   * Construct this service as a `singleton`.
   * This will ensure correct session management.
   */
  constructor() {
    if (!AuthZeroAuthenticationService.authZeroAuthenticationService) {
      AuthZeroAuthenticationService.authZeroAuthenticationService = this;

      this.authConfig = new auth0.WebAuth({
        clientID: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID'),
        domain: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_DOMAIN'),
        redirectUri: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_CALLBACK_URL'),
        responseType: 'token id_token',
        scope: 'openid profile name email picture update:password'
      });
    }

    return AuthZeroAuthenticationService.authZeroAuthenticationService;
  }

  clearLocalStorage = () => {
    LocalStorageService.removeAccessToken();
    LocalStorageService.removeAuthProvider();
    LocalStorageService.removeExpiresAt();
    LocalStorageService.removeIdToken();
  };

  getUserProfile = ()  => new Promise(resolve => {
    HttpRequestHelpers.get(CURRENT_USER_URL, HttpRequestHelpers.getDefaultHeaders())
        .then(response => resolve(new User(response.response)))
        .catch(() => {
          const errorMessage = UNABLE_TO_RETRIEVE_USER_PROFILE;
          Toast.error(errorMessage);
          console.log(errorMessage);
          resolve(null);
        })
  });

  handleAuthentication = () => new Promise((resolve, reject) => {
    this.authConfig.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        this.authConfig.client.userInfo(authResult.accessToken, (err, user) => {
          LoggingHelpers.logDev('Received user from Auth0: ' + JSON.stringify(user));
        });

        // Dispatch a `SESSION_CHECK_INITIATED` action
        // to inform restricted components to re-render if necessary
        // now that the sign in flow is complete.
        store.dispatch(checkSession());

        return resolve();
      }

      console.log(error);
      return reject(error);
    });
  });

  renewSession() {
    LoggingHelpers.logDev("Renewing stored access and ID tokens...");

    this.authConfig.checkSession({}, (error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.getUserProfile(() => {});
      }
      else if (error) {
        this.signOut();
        const errorMessage = `${FAILED_TO_REFRESH_SESSION}. ${error.error}: ${error.error_description}`;
        console.log(errorMessage);
        Toast.error(errorMessage);
      }
    });
  };

  /**
   * Based on the expiration time defined by `expiresAt`,
   * this will schedule a renewal for 5 seconds prior to the token expiring.
   */
  scheduleRenewal = () => {
    const timeout = LocalStorageService.getExpiresAt() - Date.now() - 5000;
    LoggingHelpers.logDev(`Scheduling renewal for ${timeout / 1000} seconds from now...`);

    if (timeout > 0) {
      this.tokenRenewalTimeoutId = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
    else {
      this.renewSession();
    }
  };

  setSession = (authResult) => {
    const decodedIdToken = jwt.decode(authResult.idToken);
    const expiresAt = new Date(decodedIdToken.exp * 1000).getTime();

    LocalStorageService.setAccessToken(authResult.accessToken);
    LocalStorageService.setExpiresAt(expiresAt);
    LocalStorageService.setIdToken(authResult.idToken);

    this.scheduleRenewal();
  };

  signIn = () => {
    this.authConfig.authorize();
  };

  signOut = () => {
    this.clearLocalStorage();
    clearTimeout(this.tokenRenewalTimeoutId);

    this.authConfig.logout({
      clientID: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID'),
      returnTo: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_LOGOUT_URL')
    });
  };
}

const authZeroAuthenticationService = new AuthZeroAuthenticationService();

export default authZeroAuthenticationService;
