import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { logOut } from '../actions/authentication-actions';

import Header from '../components/header/Header';
import authenticationService from '../services/AuthenticationService';

const mapStateToProps = (state) => ({
	sessionLastCheckedAt: state.authentication.sessionLastCheckedAt
});

const mapDispatchToProps = (dispatch) => ({
	getUserProfile: () => authenticationService.getUserProfile(),
	isAuthenticated: () => authenticationService.isAuthenticated(),
	logOut: () => dispatch(logOut())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
