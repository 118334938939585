import { LocalStorageService } from 'apriori-react-core';

import { ROUTE_NOT_AUTHENTICATED, ROUTE_SOMETHING_WENT_WRONG } from '../constants/routes';
import history from '../history';

import aprioriAuthenticationService from './AprioriAuthenticationService';
import authZeroAuthenticationService from './AuthZeroAuthenticationService';

class AuthenticationService {

  authenticationProvider;
  userProfile;
  userProfileTimeout;

  /*
   * During construction of this `singleton`,
   * assign `this.authenticationProvider` to the relevant 'concrete' object
   * using the authentication provider found in Local Storage.
   *
   * If the user has not previously signed,
   * `this.authenticationProvider` will remain null.
   */
  constructor() {
    if (!AuthenticationService.authenticationService) {
      AuthenticationService.authenticationService = this;
    }

    if (LocalStorageService.getAuthProvider() === 'AP_PRO') {
      this.authenticationProvider = aprioriAuthenticationService;
    }

    if (LocalStorageService.getAuthProvider() === 'AUTH0') {
      this.authenticationProvider = authZeroAuthenticationService;
    }

    return AuthenticationService.authenticationService;
  }

  handleAuthentication = () => this.authenticationProvider.handleAuthentication();

  /**
   * A user is considered authenticated if:
   * - The authentication provider has been assigned.
   * - An expiry time is present in Local Storage.
   * - An ID token is present in Local Storage.
   * - The user profile can be successfully retained and understood.
   */
  isAuthenticated = async () => {
    const currentTime = new Date().getTime();
    const expiresAt = LocalStorageService.getExpiresAt();
    const idToken = LocalStorageService.getIdToken();

    // Is the user authenticated,
    // i.e. has the user logged in using the authentication provider?
    const isAuthenticated =
        !!this.authenticationProvider &&
        currentTime < expiresAt &&
        !!idToken;

    if (!isAuthenticated) {
      return false;
    }

    // If the page is refreshed while an ID token is still valid,
    // there will be no timeout created to renew the token,
    // in this case we must manually schedule a token renewal.
    if (!this.authenticationProvider.tokenRenewalTimeoutId &&
        LocalStorageService.getAuthProvider() === 'AUTH0') {
      this.authenticationProvider.scheduleRenewal();
    }

    const profile = await this.getUserProfile();

    // Is the user authorized,
    // i.e. does the corresponding API accept a request?
    const isAuthorized = (!!profile && !!profile.identity);

    if (!isAuthorized && LocalStorageService.getAuthProvider() === 'AP_PRO') {
      history.push(ROUTE_SOMETHING_WENT_WRONG);
    }
    if (!isAuthorized) {
      history.push(ROUTE_NOT_AUTHENTICATED);
    }

    return isAuthorized;
  };

  getUserProfile = async () => {
    if (this.userProfile) {
      return this.userProfile;
    }

    this.userProfile = this.authenticationProvider.getUserProfile();

    // Temporarily store the user profile
    // to avoid many requests to retrieve the user profile
    // in quick succession.
    this.userProfileTimeout = setTimeout(() => {
      this.userProfile = null
    }, 5000);

    return this.userProfile;
  };

  /*
   * During the sign in flow, store the authentication provider in Local Storage
   * and assign `this.authenticationProvider` to the relevant 'concrete' object.
   */
  signIn = (token = null) => {
    if (token) {
      LocalStorageService.setAuthProvider('AP_PRO');
      this.authenticationProvider = aprioriAuthenticationService;
      this.authenticationProvider.signIn(token);
    }
    else {
      LocalStorageService.setAuthProvider('AUTH0');
      this.authenticationProvider = authZeroAuthenticationService;
      this.authenticationProvider.signIn();
    }
  };

  signOut = () => this.authenticationProvider.signOut();
}

const authenticationService = new AuthenticationService();

export default authenticationService;
