import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Progress } from 'reactstrap';

import { FormatHelpers } from 'apriori-react-core';

class BillOfMaterialsRemainingWork extends Component {

  constructor(props) {
    super(props);

    // Create a ref to store the container.
    this.containerRef = React.createRef();

    this.state = {
      panelHeight: undefined,
      panelWidth: undefined
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateViewSize);
  };

  componentDidUpdate() {
    this.updateViewSize();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewSize);
  };

  calcPercentage = (val, total) => {
    if (!val) {
      return 0;
    }
    const percentageProgress = (val / total) * 100;
    return parseInt(percentageProgress.toFixed(0), 10);
  };

  classNames = () => this.props.isMinimized
    ? 'bill-of-materials-remaining-work is-minimized'
    : 'bill-of-materials-remaining-work';

  updateViewSize = () => {
    const { panelHeight, panelWidth } = this.state;
    if (!this.containerRef.current) {
      return;
    }
    const currentPanelHeight = this.containerRef.current.parentNode.clientHeight;
    const currentPanelWidth = this.containerRef.current.parentNode.clientWidth;

    if (currentPanelHeight !== panelHeight || currentPanelWidth !== panelWidth) {
      this.setComponentSize(currentPanelHeight, currentPanelWidth);
    }
  };

  setComponentSize = (height, width) => this.setState({ panelHeight: height, panelWidth: width });

  renderMinimized() {
    const { billOfMaterials } = this.props;

    const {
      numberOfLineItemsReadyForExport,
      numberOfLineItemsIncomplete,
      numberOfLineItemsNoPartsMatched,
      totalNumberOfLineItems
    } = billOfMaterials;

    const fullMatchStyle = {
      width: `${this.calcPercentage(numberOfLineItemsReadyForExport, totalNumberOfLineItems)}%`,
      backgroundColor: 'rgba( 1, 176, 80, 0.6)'
    };
    const partialMatchStyle = {
      width: `${this.calcPercentage(numberOfLineItemsIncomplete, totalNumberOfLineItems)}%`,
      backgroundColor: 'rgba( 1, 176, 80, 0.3)'
    };
    const noMatchStyle = {
      width: `${this.calcPercentage(numberOfLineItemsNoPartsMatched, totalNumberOfLineItems)}%`,
      backgroundColor: 'rgba( 1, 176, 80, 0.1)'
    };

    return (
      <Progress multi>
        <div className="progress-bar" style={fullMatchStyle} />
        <div className="progress-bar" style={partialMatchStyle} />
        <div className="progress-bar" style={noMatchStyle} />
      </Progress>
    );
  }

  renderRectangle(height, width, x, y, opacity) {
    return (
      <rect
        height={height}
        width={width}
        x={x}
        y={y}
        style={{ fill: "rgb(1,176,80)", stroke: "rgb(1,176,80)", strokeWidth: "1.0", fillOpacity: opacity }}
      />
    );
  }

  renderSvg() {
    const { billOfMaterials } = this.props;
    const { panelHeight, panelWidth } = this.state;

    if (!panelHeight || !panelWidth) {
      return;
    }

    const {
      numberOfLineItemsReadyForExport,
      numberOfLineItemsIncomplete,
      numberOfLineItemsNoPartsMatched,
      totalNumberOfLineItems
    } = billOfMaterials;

    const svgHeight = 65;
    const paddingX = 20;
    const strokeWidth = 1;
    const height = 18;
    const width = panelWidth - (2 * paddingX);

    const rectangleOffsetY = (panelHeight / 2) - (height / 2);
    const textOffsetY = 20;

    const readyPercent = this.calcPercentage(numberOfLineItemsReadyForExport, totalNumberOfLineItems);
    const incompletePercent = this.calcPercentage(numberOfLineItemsIncomplete, totalNumberOfLineItems);
    const noMatchPercent = this.calcPercentage(numberOfLineItemsNoPartsMatched, totalNumberOfLineItems);

    const readyWidth = (width * readyPercent) / 100.0 - (strokeWidth * 2);
    const incompleteWidth = (width * incompletePercent) / 100.0 - (strokeWidth * 2);
    const noMatchWidth = (width * noMatchPercent) / 100.0 - (strokeWidth * 2);

    const readyStart = paddingX;
    const incompleteStart = readyStart + readyWidth;
    const noMatchStart = incompleteStart + incompleteWidth;

    const lowerTextOffsetY = (panelHeight / 2) + textOffsetY + 4;
    const upperTextOffsetY = (panelHeight / 2) - textOffsetY;
    const readyTextStart = paddingX + 20;
    const incompleteTextStart = Math.max(incompleteStart - 20, paddingX);
    const noMatchTextStart = panelWidth - 80;

    return (
      <svg width={panelWidth} height={svgHeight} style={{ verticalAlign: "center" }}>

        {numberOfLineItemsReadyForExport > 0 && this.renderRectangle(height, readyWidth, readyStart, rectangleOffsetY, 0.6)}
        {numberOfLineItemsIncomplete > 0 && this.renderRectangle(height, incompleteWidth, incompleteStart, rectangleOffsetY, 0.3)}
        {numberOfLineItemsNoPartsMatched > 0 && this.renderRectangle(height, noMatchWidth, noMatchStart, rectangleOffsetY, 0.1)}

        {numberOfLineItemsReadyForExport > 0 && ([
          this.renderTooltipText(
            `Match Complete (${FormatHelpers.formatAsInteger(numberOfLineItemsReadyForExport)})`,
            readyTextStart,
            upperTextOffsetY
          ),
          this.renderTooltipLine(
            readyTextStart + 20,
            upperTextOffsetY + 4,
            readyStart + (readyWidth / 2),
            rectangleOffsetY + height / 2
          )
        ])}

        {numberOfLineItemsIncomplete > 0 && ([
          this.renderTooltipText(
            `Match - Action needed (${FormatHelpers.formatAsInteger(numberOfLineItemsIncomplete)})`,
            incompleteTextStart,
            lowerTextOffsetY
          ),
          this.renderTooltipLine(
            incompleteTextStart + 40,
            lowerTextOffsetY - 7,
            incompleteStart + (incompleteWidth / 2),
            rectangleOffsetY + height / 2
          )
        ])}

        {numberOfLineItemsNoPartsMatched > 0 && ([
          this.renderTooltipText(
            `No Match (${FormatHelpers.formatAsInteger(numberOfLineItemsNoPartsMatched)})`,
            noMatchTextStart,
            upperTextOffsetY
          ),
          this.renderTooltipLine(
            noMatchTextStart + 30,
            upperTextOffsetY + 4,
            noMatchStart + (noMatchWidth / 2),
            rectangleOffsetY + height / 2
          )
        ])}
      </svg>
    );
  }

  renderTooltipLine(x1, y1, x2, y2) {
    return (
      <line key='line' x1={x1} y1={y1} x2={x2} y2={y2} style={{ stroke: "black", strokeWidth: "0.5" }} />
    );
  }

  renderTooltipText(message, x, y) {
    return (
      <text key='text' x={x} y={y} fontSize={7} fill='black'>
        <tspan>{message}</tspan>
      </text>
    );
  }

  render() {
    const { isMinimized } = this.props;

    return (
      <div ref={this.containerRef} className={this.classNames()}>
        {isMinimized ? this.renderMinimized() : this.renderSvg()}
      </div>
    );
  }
}

BillOfMaterialsRemainingWork.defaultProps = {
  isMinimized: false
};

BillOfMaterialsRemainingWork.propTypes = {
  billOfMaterials: PropTypes.instanceOf(Object).isRequired,
  isMinimized: PropTypes.bool
};

export default BillOfMaterialsRemainingWork;
